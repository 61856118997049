import React, { useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Divider, Grid, Paper, Rating, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { ANSWERQUERY, SHORTREADDETAIL } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ResourcesList } from 'Components';
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { Can } from 'Utils/Can';



const styles = {
    tableSize: {
        minHeight: 'calc(100vh - 250px)',
        width: '100%',
    },
    h5Text: {
        textTransform: 'capitalize',
        color: 'red',
    }
};

const useStyles = makeStyles((theme) => ({
    h5Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)'
    },
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    pText: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,0.8)'
    },
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    },
    btn_completed: {
        cursor: "default",
        background: "none",
        '&:hover': {
            background: "none",
        },
        '&:disabled': {
            color: 'rgba(3, 166, 90,0.7)',
            background: "none",
        }
    },
    btn_pending: {
        cursor: "default",
        color: "rgb(252, 232, 55)",
        background: "none",
        '&:hover': {
            background: "none",
        }
    },
    btn_rejected: {
        cursor: "default",
        color: "rgb(255, 78, 78)",
        background: "none",
        '&:hover': {
            background: "none",
        }
    }
}))

// Component goes here
const ViewAnswer = () => {
    const spacing = 4
    const classes = useStyles();
    const history = useHistory();
    const { shortReadId } = useParams();
    const componentRef = useRef();
    const { t, i18n } = useTranslation();
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const location = useLocation();
    const answerId = location?.state?.answer?.id

    const shortReadQuery = useQuery([SHORTREADDETAIL, { shortReadId }], () =>
        http.get(`/question_and_answer/${shortReadId}`, {}).then(({ data }) => data),
    );

    const answerQuery = useQuery([ANSWERQUERY, { answerId }], () =>
        http.get(`/answer/${answerId}`, {}).then(({ data }) => {
            return (data)
        }),
    );

    const handleStatus = async (type, status) => {
        if (type === "answer") {
            const dataUpdate = { status: status }
            try {
                await http.put(`/answer/status_update/${answerId}`, dataUpdate)
                    .then(async (res) => {
                        toast.success(t('toast.success.updatedSuccessfully'))
                        answerQuery.refetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        }
    };

    const handleSoftDelete = async () => {

        try {
            await http.delete(`/answer/${answerId}`, {})
                .then(async (res) => {
                    toast.success(t('toast.success.deletedSuccessfully'))
                    history.goBack();
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }

        setOpenDeleteModal(false)
    };
    return (
        <>
            <Grid item xs={12}>
                <ResourcesList
                    BeforeHeader={
                        <Breadcrumbs className="mb-4">
                            <Link component={NavLink} to="/admin/article">
                                {t("admin.company.qna.answer")}
                            </Link>
                            <Typography color="textPrimary">#{shortReadQuery?.data?.data?.title}</Typography>
                        </Breadcrumbs>
                    }
                    Section={
                        <div className="mt-8">
                            <Box sx={styles.tableSize}>
                                <Paper>
                                    <Card variant="outlined" style={{ padding: '20px' }}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item>
                                                <h3 className={classes.h3Text}>{t("admin.company.qna.ansDetails")}</h3>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item>
                                                        <ReactToPrint
                                                            trigger={() => <IconButton style={{ color: "rgb(149, 230, 255)" }} ><PrintIcon /></IconButton>}
                                                            content={() => componentRef.current}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton className={classes.btnClose} onClick={() => setOpenDeleteModal(true)} ><DeleteOutlineOutlinedIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider style={{ margin: '10px 0' }} />
                                        <div ref={componentRef} style={{ padding: "20px", direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}>
                                            <Grid container spacing={spacing}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={spacing}>
                                                        <Grid item xs={10}>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.questionTitle")}:</h5>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <p className={classes.pText}>{shortReadQuery?.data?.data?.title}</p>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.questionDesc")}:</h5>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <div className='editor' dangerouslySetInnerHTML={{ __html: shortReadQuery?.data?.data?.questionDescription }}></div>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.answerDesc")} :</h5>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <div className='editor' dangerouslySetInnerHTML={{ __html: location.state.answer?.answerDescription }}></div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Can I="CREATE" this="REQUEST">
                                                                <Grid container spacing={2}>
                                                                    {
                                                                        answerQuery?.data?.data?.status === "completed" ?
                                                                            <>
                                                                                <Button fullWidth color="primary" disabled className={classes.btn_completed}>{answerQuery?.data?.data?.status === "completed" ? t("admin.requests.approved"):answerQuery?.data?.data?.status}</Button>
                                                                            </>
                                                                            : answerQuery?.data?.data?.status === "pending" ?
                                                                                <>
                                                                                    <Grid item xs={6}>
                                                                                        <Button fullWidth color="primary" onClick={() => handleStatus('answer', "completed")}>Accept</Button>
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <Button fullWidth color="error" onClick={() => handleStatus('answer', "rejected")}>Reject</Button>
                                                                                    </Grid>
                                                                                </>
                                                                                : answerQuery?.data?.data?.status === "rejected" ?
                                                                                    <>
                                                                                        <Grid item xs={12}>
                                                                                            <Button fullWidth color="primary" className={classes.btn_rejected}>{answerQuery?.data?.data?.status === "rejected" ? t("admin.requests.rejected"):answerQuery?.data?.data?.status}</Button>
                                                                                        </Grid>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                    }
                                                                </Grid>
                                                            </Can>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.closingDesc")}</h5>
                                                                </Grid>
                                                            </Grid>
                                                            {location.state.answer.closing?.map((item) => {
                                                                return (
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <Divider style={{ margin: "10px 0" }} />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Grid container>
                                                                                <Grid item xs={12} md={8} lg={9}>
                                                                                    <div className='editor' dangerouslySetInnerHTML={{ __html: item?.closingDescriptionText }}></div>
                                                                                </Grid>
                                                                                <Grid item xs={12} md={4} lg={3}>
                                                                                    <Rating value={item?.closingDescriptionRating} readOnly />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                </Paper>
                            </Box>
                            <ConfirmationDialog isOpen={openDeleteModal} title={t("common.delete")} text={t("delete.deleteAnswer")} onReject={() => setOpenDeleteModal(false)} onResolve={handleSoftDelete} />
                        </div>
                    }
                />
            </Grid>
        </>
    );
};

export default ViewAnswer;
