import React, { useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { ANSWERQUERY, SHORTREADDETAIL } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ResourcesList } from 'Components';
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import { DataGrid, IconBtnBase } from 'Components';
import { toast } from 'react-toastify';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Can } from 'Utils/Can';
import FileDownload from 'Components/PdfDownload/FileDownload';

import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';


const styles = {
    tableSize: {
        minHeight: 'calc(100vh - 250px)',
        width: '100%',
    },
    h5Text: {
        textTransform: 'capitalize',
        color: 'red',
    }
};

const useStyles = makeStyles((theme) => ({
    h5Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)'
    },
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    pText: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,0.8)'
    },
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    },
    btn_completed: {
        cursor: "default",
        background: "none",
        '&:hover': {
            background: "none",
        },
        '&:disabled': {
            color: 'rgba(3, 166, 90,0.7)',
            background: "none",
        }
    },
    btn_pending: {
        cursor: "default",
        color: "rgb(252, 232, 55)",
        background: "none",
        '&:hover': {
            background: "none",
        }
    },
    btn_rejected: {
        cursor: "default",
        color: "rgb(255, 78, 78)",
        background: "none",
        '&:hover': {
            background: "none",
        }
    }
}))





// Component goes here
const QnADetailsPage = () => {
    const spacing = 4
    const classes = useStyles();
    const history = useHistory();
    const { shortReadId } = useParams();
    const componentRef = useRef();
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(1);
    const perPage = 10;
    const rowData = [];
    const [answerArray, setAnswerArray] = useState()
    const [companyId, setCompanyId] = useState();
    const [createdById, setCreatedById] = useState();
    const shortReadQuery = useQuery([SHORTREADDETAIL, { shortReadId }], () =>
        http.get(`/question_and_answer/${shortReadId}`, {}).then(({ data }) => {
            setCompanyId(data?.data?.companyId);
            setCreatedById(data?.data?.createdBy);
            return (data)
        }),
    );

    const createdByQuery = useQuery([SHORTREADDETAIL, { createdById }], () =>
        http.get(`/customer_executive/${createdById}`, {}).then(({ data }) => {
            return (data)
        }),
    );


    const companyQuery = useQuery([SHORTREADDETAIL, { companyId }], () =>
        http.get(`/company/${companyId}`, {}).then(({ data }) => {
            return (data)
        }),
    );
    const answerQuery = useQuery([ANSWERQUERY, { shortReadId, page }], () =>
        http.get(`/answer`, {
            params: {
                page: page,
                questionId: shortReadId
            }
        }).then(({ data }) => setAnswerArray(data?.data?.data)),
    );
    if (answerArray?.length > 0) {
        for (let i = 0; i < answerArray?.length; i++) {
            rowData[i] = {
                answerDescription: answerArray[i]?.answerDescription,
                closing: answerArray[i].closing,
                id: answerArray[i]?._id,
                status: answerArray[i]?.status
            }
        }
    }
    const handleStatus = async (type, status) => {
        if (type === "question") {
            const dataUpdate = { status: status }
            try {
                await http.put(`/question_and_answer/status_update/${shortReadId}`, dataUpdate)
                    .then(async (res) => {
                        toast.success(t('toast.success.updatedSuccessfully'))
                        shortReadQuery.refetch()
                        answerQuery.refetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        } else if (type === "awareness") {
            const dataUpdate = {
                awareness: {
                    description: shortReadQuery?.data?.data?.awareness?.description,
                    attachment: shortReadQuery?.data?.data?.awareness?.attachment,
                    status: status
                }
            }
            try {
                await http.put(`/question_and_answer/${shortReadId}`, dataUpdate)
                    .then(async (res) => {
                        toast.success(t('toast.success.updatedSuccessfully'))
                        shortReadQuery.refetch()
                        answerQuery.refetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        } else if (type === "committee") {
            const dataUpdate = {
                committee: {
                    decisionType: shortReadQuery?.data?.data?.committee?.decisionType,
                    attachment: shortReadQuery?.data?.data?.committee?.attachment,
                    status: status
                }
            }
            try {
                await http.put(`/question_and_answer/${shortReadId}`, dataUpdate)
                    .then(async (res) => {
                        toast.success(t('toast.success.updatedSuccessfully'))
                        shortReadQuery.refetch()
                        answerQuery.refetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        }
    };

    const columns = [

        {
            field: 'school2',
            headerName: t('admin.company.qna.answerDesc'),
            minWidth: 250,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <div className='editor' dangerouslySetInnerHTML={{ __html: row?.row?.answerDescription }}></div>

                        {/* {row?.row?.questionDescription} */}
                    </div>
                );
            },
        },
        {
            field: 'school1',
            headerName: t('admin.company.qna.numberOfClosing'),
            minWidth: 250,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <div className='editor' dangerouslySetInnerHTML={{ __html: row?.row?.closing?.length }}></div>

                        {/* {row?.row?.questionDescription} */}
                    </div>
                );
            },
        },
        {
            field: 'school3',
            headerName: t('admin.company.qna.status'),
            minWidth: 250,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <div>
                            {row?.row?.status === "pending" ? t("admin.company.create.pending") : row?.row?.status === "rejected" ? t("admin.requests.rejected") : row?.row?.status}
                        </div>
                        {/* {row?.row?.questionDescription} */}
                    </div>
                );
            },
        },

        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {/* <Grid item>
                            <IconButton className={classes.btnEdit} onClick={() => history.push(`/admin/company/details/${companyId}/qna/edit/${shortReadId}`)} ><BorderColorOutlinedIcon /></IconButton>
                        </Grid> */}
                        <IconBtnBase
                            onClick={() => history.push({
                                pathname: `/admin/requests/details/question/${shortReadId}/qna/requestviewanswer/`,
                                state: { answer: row?.row }
                            })}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                        {/* <IconButton style={{ color: 'rgba(255,0,0,0.5)' }} onClick={() => { setOpenDeleteModal2(true); setDeletedAns(row?.row?.id) }}><DeleteOutlineOutlinedIcon /></IconButton> */}
                    </div>
                );
            },
        },
    ];


    return (
        <>
            <Grid item xs={12}>
                <ResourcesList
                    BeforeHeader={
                        <Breadcrumbs className="mb-4">
                            <Link component={NavLink} to="/admin/article">
                                {t("admin.company.qna.title")}
                            </Link>
                            <Typography color="textPrimary">#{shortReadQuery?.data?.data?.title}</Typography>
                        </Breadcrumbs>
                    }
                    Section={
                        <div className="mt-8">
                            <Box sx={styles.tableSize}>
                                <Paper>
                                    <Card variant="outlined" style={{ padding: '20px' }}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item>
                                                <h3 className={classes.h3Text}>{t("admin.company.qna.qnaDetails")}</h3>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    {/* <Grid item>
                                                        <IconButton className={classes.btnEdit} onClick={() => history.push(`/admin/company/details/${companyId}/qna/edit/${shortReadId}`)} ><BorderColorOutlinedIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton className={classes.btnClose} onClick={() => setOpenDeleteModal(true)} ><DeleteOutlineOutlinedIcon /></IconButton>
                                                    </Grid> */}
                                                    <Grid item>
                                                        <ReactToPrint
                                                            trigger={() => <IconButton style={{ color: "rgb(149, 230, 255)" }} ><PrintIcon /></IconButton>}
                                                            content={() => componentRef.current}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider style={{ margin: '10px 0' }} />
                                        <div ref={componentRef} style={{ padding: "20px", direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}>
                                            <Grid container spacing={spacing}>
                                                <Grid item xs={12}>
                                                    <Grid container justifyContent="space-between" spacing={spacing}>
                                                        <Grid item xs={9}>
                                                            <Grid container >
                                                                <Grid item xs={12} md={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.title")}:</h5>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <p className={classes.pText}>{companyQuery?.data?.data?.title}</p>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.category")}:</h5>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <p className={classes.pText}>{companyQuery?.data?.data?.category}</p>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <img src={companyQuery?.data?.data?.logo} style={{ height: "100px" }} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{ margin: "10px 0" }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={spacing}>
                                                        <Grid item xs={9}>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.createdBy")}:</h5>
                                                                </Grid>
                                                                {console.log(createdByQuery?.data?.data?.name)}
                                                                <Grid item xs={8}>
                                                                    <p className={classes.pText}>{createdByQuery?.data?.data?.name}</p>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.questionTitle")}:</h5>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <p className={classes.pText}>{shortReadQuery?.data?.data?.title}</p>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.questionDesc")}:</h5>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <div className='editor' dangerouslySetInnerHTML={{ __html: shortReadQuery?.data?.data?.questionDescription }}></div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Can I="CREATE" this="REQUEST">
                                                                <Grid container spacing={2}>
                                                                    {
                                                                        shortReadQuery?.data?.data?.status === "completed" ?
                                                                            <>
                                                                                <Button fullWidth color="primary" disabled className={classes.btn_completed}>Approved</Button>
                                                                            </>
                                                                            : shortReadQuery?.data?.data?.status === "pending" ?
                                                                                <>
                                                                                    <Grid item xs={6}>
                                                                                        <Button fullWidth color="primary" onClick={() => handleStatus('question', "completed")}>Accept</Button>
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <Button fullWidth color="error" onClick={() => handleStatus('question', "rejected")}>Reject</Button>
                                                                                    </Grid>
                                                                                </>
                                                                                : shortReadQuery?.data?.data?.status === "rejected" ?
                                                                                    <>
                                                                                        <Grid item xs={12}>
                                                                                            <Button fullWidth color="primary" className={classes.btn_rejected}>{shortReadQuery?.data?.data?.status}</Button>
                                                                                        </Grid>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                    }
                                                                </Grid>
                                                            </Can>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={spacing} justifyContent="space-between">
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.answer")}:</h5>
                                                                </Grid>
                                                                <Grid item xs={8} className='text-end'>
                                                                    <div>
                                                                        {/* <IconBtnBase
                                                                        onClick={() => {
                                                                            history.push(`/admin/company/details/${shortReadId}/qna/createanswer/`);
                                                                        }}
                                                                        size="small">
                                                                        <AddIcon /><span> Add Answer</span>
                                                                    </IconBtnBase> */}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <DataGrid
                                                                        pagination
                                                                        rowCount={rowData?.length}
                                                                        paginationMode="server"
                                                                        onPageChange={(newPage) => setPage(newPage + 1)}
                                                                        loading={answerQuery?.isLoading}
                                                                        rows={rowData}
                                                                        columns={columns}
                                                                        pageSize={perPage}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {shortReadQuery?.data?.data?.awareness ?
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Divider style={{ margin: "10px 0" }} />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container>
                                                                        <Grid item xs={9}>
                                                                            <Grid container spacing={spacing}>
                                                                                <Grid item xs={12}>
                                                                                    <h5 className={classes.h4Text}>{t("admin.company.qna.awarenessMaterial")}</h5>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.description")}:</h5>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <p className={classes.pText}>
                                                                                        <div className='editor' dangerouslySetInnerHTML={{ __html: shortReadQuery?.data?.data?.awareness?.description }}></div>
                                                                                    </p>
                                                                                </Grid>
                                                                                {shortReadQuery?.data?.data?.awareness?.attachment?.length ?
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container spacing={spacing}>
                                                                                            <Grid item xs={12}>
                                                                                                <h5 className={classes.h5Text}>{t("admin.article.attachments")}</h5>
                                                                                            </Grid>
                                                                                            {shortReadQuery?.data?.data?.awareness?.attachment?.map((item) => {
                                                                                                return (
                                                                                                    <Grid item xs={12} md={6} lg={4}>
                                                                                                        <FileDownload file={item} />
                                                                                                    </Grid>
                                                                                                )
                                                                                            })}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    : null}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <Can I="CREATE" this="REQUEST">
                                                                                <Grid container spacing={2}>
                                                                                    {
                                                                                        shortReadQuery?.data?.data?.awareness?.status === "completed" ?
                                                                                            <>
                                                                                                <Button fullWidth color="primary" disabled className={classes.btn_completed}>Approved</Button>
                                                                                            </>
                                                                                            : shortReadQuery?.data?.data?.awareness?.status === "pending" ?
                                                                                                <>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Button fullWidth color="primary" onClick={() => handleStatus('awareness', "completed")}>Accept</Button>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Button fullWidth color="error" onClick={() => handleStatus('awareness', "rejected")}>Reject</Button>
                                                                                                    </Grid>
                                                                                                </>
                                                                                                : shortReadQuery?.data?.data?.awareness?.status === "rejected" ?
                                                                                                    <>
                                                                                                        <Grid item xs={12}>
                                                                                                            <Button fullWidth color="primary" className={classes.btn_rejected}>{shortReadQuery?.data?.data?.awareness?.status}</Button>
                                                                                                        </Grid>
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                    }
                                                                                </Grid>
                                                                            </Can>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider style={{ margin: "10px 0" }} />
                                                                </Grid>
                                                            </>
                                                            : null}
                                                        {shortReadQuery?.data?.data?.committee ?
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Divider style={{ margin: "10px 0" }} />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container>
                                                                        <Grid item xs={9}>
                                                                            <Grid container spacing={spacing}>
                                                                                <Grid item xs={12}>
                                                                                    <h5 className={classes.h4Text}>{t("admin.company.qna.committeeDecisions")}</h5>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <h5 className={classes.h5Text}>{t("admin.company.qna.decision")}:</h5>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <p className={classes.pText}>
                                                                                        {shortReadQuery?.data?.data?.committee?.decisionType}
                                                                                    </p>
                                                                                </Grid>
                                                                                {shortReadQuery?.data?.data?.committee?.attachment?.length ?
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container spacing={spacing}>
                                                                                            <Grid item xs={12}>
                                                                                                <h5 className={classes.h5Text}>{t("admin.article.attachments")}</h5>
                                                                                            </Grid>
                                                                                            {shortReadQuery?.data?.data?.committee?.attachment?.map((item) => {
                                                                                                return (
                                                                                                    <Grid item xs={12} md={6} lg={4}>
                                                                                                        <FileDownload file={item} />
                                                                                                    </Grid>
                                                                                                )
                                                                                            })}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    : null}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <Can I="CREATE" this="REQUEST">
                                                                                <Grid container spacing={2}>
                                                                                    {
                                                                                        shortReadQuery?.data?.data?.committee?.status === "completed" ?
                                                                                            <>
                                                                                                <Button fullWidth color="primary" disabled className={classes.btn_completed}>Approved</Button>
                                                                                            </>
                                                                                            : shortReadQuery?.data?.data?.committee?.status === "pending" ?
                                                                                                <>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Button fullWidth color="primary" onClick={() => handleStatus('committee', "completed")}>Accept</Button>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Button fullWidth color="error" onClick={() => handleStatus('committee', "rejected")}>Reject</Button>
                                                                                                    </Grid>
                                                                                                </>
                                                                                                : shortReadQuery?.data?.data?.committee?.status === "rejected" ?
                                                                                                    <>
                                                                                                        <Grid item xs={12}>
                                                                                                            <Button fullWidth color="primary" className={classes.btn_rejected}>{shortReadQuery?.data?.data?.committee?.status}</Button>
                                                                                                        </Grid>
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                    }
                                                                                </Grid>
                                                                            </Can>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                            : null}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                </Paper>
                            </Box>
                        </div>
                    }
                />
            </Grid>
        </>
    );
};

export default QnADetailsPage;
