// export const FUNDS = 'FUNDS';
// export const ACCOUNTS = 'ACCOUNTS';
// export const ACCOUNTDETAIL = 'ACCOUNTDETAIL';
export const VIDEODETAIL='VIDEODETAIL'
export const VIDEOLIST='VIDEOLIST'
export const MAGAZINEDETAIL='MAGAZINEDETAIL'
export const MAGAZINELIST='MAGAZINELIST'
export const SHORTREADDETAIL='SHORTREADDETAIL'
export const SHORTREADLIST='SHORTREADLIST'

export const PROCESSDETAIL='PROCESSDETAIL'
export const ANSWERQUERY='ANSWERQUERY'



export const USERDETAIL='USERDETAIL'
export const USERLIST='USERLIST'
export const CUSTOMEREXECUTIVELIST='CUSTOMEREXECUTIVELIST'

export const ARTICLEDETAIL='ARTICLEDETAIL'
export const ARTICLELIST='ARTICLELIST'

export const ARTICLECATEGORYSUBCATEGORY='ARTICLECATEGORYSUBCATEGORY'




