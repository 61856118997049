import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  setProgress,
  RadioGroupController,
  ResourcesList,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { SHORTREADDETAIL } from "Constants/QueriesKeys";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import FileController from "Components/FormControls/Controllers/FileController";
import FileDownload from "Components/PdfDownload/FileDownload";


const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";
// const schema = Yup.object().shape({
//   closingDescriptionRating:
// });
const ticketSizeObjects = Yup.object({
  closingDescriptionRating: Yup
    .number()
    .required("Required"),
});


const schema = Yup.object({
  closing: Yup
    .array()
    .of(ticketSizeObjects)
});
const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  h4Text: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
}))
function UpdateCommittee() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { shortReadId } = useParams();
  const location = useLocation();



  const shortReadQuery = useQuery([SHORTREADDETAIL, { shortReadId }], () =>
    http.get(`/question_and_answer/${shortReadId}`, {}).then(({ data }) => { return data; }),
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: location?.state?.committee ? {
      decisionType: location?.state?.committee?.decisionType,
    } : null
  });
  const { control, handleSubmit, setError, reset, errors } = form;

  const handleFormSubmission = async (values) => {

    setProgress(CREATE_PAYOUT_BTN, true);
    const data = {
      committee: {
        decisionType: values?.decisionType,
        status: 'completed',
      }
    }


    let formDataCommittee = new FormData();

    for (var i = 0; i < values.committeeDecisionsFiles?.length; i++) {
      formDataCommittee.append("attachment", values.committeeDecisionsFiles[i]);
    }

    if (values.committeeDecisionsFiles?.length) {
      try {
        await http.post(`/helpers/upload-file`, formDataCommittee)
          .then(async (res) => {
            data.committee['attachment'] = res?.data?.data
            try {
              await http.put(`/question_and_answer/${shortReadId}`, data)
                .then(async (res) => {
                  history.goBack();
                  toast.success(t('toast.success.updatedSuccessfully'));
                });
              // reset({});
            } catch (error) {
              toast.error(error?.response?.data?.message);
            }
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    } else {
      data.committee['attachment'] = location.state.committee?.attachment
      try {
        await http.put(`/question_and_answer/${shortReadId}`, data)
          .then(async (res) => {
            history.goBack();
            toast.success(t('toast.success.updatedSuccessfully'));
          });
        // reset({});
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }



    setProgress(CREATE_PAYOUT_BTN, false);
  };
  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.company.qna.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{shortReadId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="py-5 space-y-3"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container >
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Grid container>
                      <Grid item xs={4}>
                        <h5 className={classes.h5Text}>{t("admin.company.qna.questionTitle")}:</h5>
                      </Grid>
                      <Grid item xs={8}>
                        <p className={classes.pText}>{shortReadQuery?.data?.data?.title}</p>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        <h5 className={classes.h5Text}>{t("admin.company.qna.questionDesc")}:</h5>
                      </Grid>
                      <Grid item xs={8}>
                        <div className='editor' dangerouslySetInnerHTML={{ __html: shortReadQuery?.data?.data?.questionDescription }}></div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h3 className={classes.h4Text}>{t('admin.company.qna.committeeDecisions')}</h3>
                  </Grid>
                  <Grid item xs={12} >
                    <Grid container >
                      <Grid item xs={12}>
                        <RadioGroupController
                          control={control}
                          name="decisionType"
                          row
                          options={[{ label: t('admin.company.qna.decisionCompensate'), value: "Decision to Compensate" }, { label: t('admin.company.qna.decisionRefuseCompensation'), value: "Decision to refuse compensation" }]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.company.qna.fileUpload")}</h6>
                      </Grid>
                      <FileController
                        name="committeeDecisionsFiles"
                        filesLimit={5}
                        acceptedFiles
                        form={form}
                        errors={errors}
                      />
                      <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <p style={{ fontSize: "12px", color: "rgba(0,0,0,0.5)" }}>Previous attachments</p>
                          </Grid>
                          {location?.state?.committee?.attachment?.map((item) => {
                            return (
                              <Grid item xs={12} md={6} lg={4}>
                                <FileDownload file={item} />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                {t("common.submit")}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}
export default UpdateCommittee;