import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AppLayout from 'Layouts/AppLayout';
import SignInPage from 'Pages/Auth/SignInPage';
import { NavLinks } from 'Constants/NavLinks';
import CompanyPage from 'Pages/Company/CompanyPage';
import CreateUpdateCompany from 'Pages/Company/CreateUpdateCompany';
import CreateUpdateNews from 'Pages/Company/CreateUpdateNews';
import NewsDetailsPage from 'Pages/Company/NewsDetailsPage';
import QnADetailsPage from 'Pages/Company/QnA/QnADetailsPage';
import ViewAnswer from 'Pages/Company/QnA/ViewAnswer';
import AddAnswer from 'Pages/Company/QnA/AddAnswer';
import CompanyDetailPage from 'Pages/Company/CompanyDetailPage';
import ArticlePage from 'Pages/Article/ArticlePage';
import ArticleDetailPage from 'Pages/Article/ArticleDetailPage';
import CreateUpdateArticle from 'Pages/Article/CreateUpdateArticle';
import UserPage from 'Pages/Users/UserPage';
import Home from 'Pages/Home/';
import CreateUpdateUser from 'Pages/Users/CreateUpdateUser';
import UserDetailPage from 'Pages/Users/UserDetailPage';
import RequestPage from 'Pages/Requests/RequestPage';
import RequestDetailPage from 'Pages/Requests/RequestDetailPage';
import RequestViewAnswer from 'Pages/Requests/components/RequestViewAnswer';
import CreateQnA from 'Pages/Company/QnA/CreateQnA';
import UpdateQnA from 'Pages/Company/QnA/UpdateQnA';
import UpdateCommittee from 'Pages/Company/QnA/UpdateCommittee';
import UpdateAwareness from 'Pages/Company/QnA/UpdateAwareness';
import ProcessGuide from 'Pages/ProcessGuide/ProcessGuide';
import EditProcessGuide from 'Pages/ProcessGuide/EditProcessGuide';

export default function App() {
    let { path } = useRouteMatch();

    return (
        <AppLayout links={NavLinks}>
            <Switch>
                <Route exact path={`${path}/sign-in`} component={SignInPage} />
                <Route exact path={`${path}`} component={Home} />
                <Route exact path={`${path}/home`} component={Home} />


                <Route exact path={`${path}/users`} component={UserPage} />
                <Route exact path={`${path}/users/create`} component={CreateUpdateUser} />
                <Route exact path={`${path}/users/:userType/edit/:userId`} component={CreateUpdateUser} />
                <Route exact path={`${path}/users/:typeOfUser/details/:userId`} component={UserDetailPage} />

                <Route exact path={`${path}/requests`} component={RequestPage} />
                <Route exact path={`${path}/requests/details/question/:shortReadId/qna/requestviewanswer/`} component={RequestViewAnswer} />
                <Route exact path={`${path}/requests/details/:companyId/qnas/:shortReadId`} component={RequestDetailPage} />


                <Route exact path={`${path}/article`} component={ArticlePage} />
                <Route exact path={`${path}/article/details/:articleId`} component={ArticleDetailPage} />
                <Route exact path={`${path}/article/edit/:articleId`} component={CreateUpdateArticle} />
                <Route exact path={`${path}/article/create`} component={CreateUpdateArticle} />

                <Route exact path={`${path}/company`} component={CompanyPage} />
                <Route exact path={`${path}/company/create`} component={CreateUpdateCompany} />
                <Route exact path={`${path}/company/edit/:shortReadId`} component={CreateUpdateCompany} />
                <Route exact path={`${path}/company/details/:shortReadId`} component={CompanyDetailPage} />
                <Route exact path={`${path}/company/details/:companyId/new/:shortReadId`} component={NewsDetailsPage} />
                <Route exact path={`${path}/company/details/:companyId/news/create`} component={CreateUpdateNews} />
                <Route exact path={`${path}/company/details/:companyId/news/edit/:newsId`} component={CreateUpdateNews} />
                <Route exact path={`${path}/company/details/:companyId/qnas/:questionId`} component={QnADetailsPage} />
                <Route exact path={`${path}/company/details/question/:shortReadId/qna/viewanswer/`} component={ViewAnswer} />
                <Route exact path={`${path}/company/details/:companyId/qna/create`} component={CreateQnA} />
                <Route exact path={`${path}/company/details/:shortReadId/qna/createanswer`} component={AddAnswer} />
                <Route exact path={`${path}/company/details/:shortReadId/qna/editcommittee`} component={UpdateCommittee} />
                <Route exact path={`${path}/company/details/:shortReadId/qna/editawareness`} component={UpdateAwareness} />
                <Route exact path={`${path}/company/details/:companyId/qna/edit/:shortReadId`} component={UpdateQnA} />
                <Route exact path={`${path}/company/edit/:shortReadId`} component={CreateUpdateCompany} />
                <Route exact path={`${path}/guide`} component={ProcessGuide} />
                <Route exact path={`${path}/guide/edit`} component={EditProcessGuide} />
            </Switch>
        </AppLayout>
    );
}
