import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  setProgress,
  ResourcesList,
  EditorController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { SHORTREADDETAIL } from "Constants/QueriesKeys";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import RatingController from "Components/FormControls/Controllers/RatingController";
import { useLocation } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const ticketSizeObjects = Yup.object({
  closingDescriptionRating: Yup
    .number()
});


const schema = Yup.object({
  closing: Yup
    .array()
    .of(ticketSizeObjects)
});
const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  h4Text: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  btn_delete: {
    fontSize: "12px",
    textTransform: "capitalize",
    color: "rgba(255,0,0,0.5)",
    margin: "0 20px",
    '&:hover': {
      color: 'rgba(255,0,0,0.7)',
    }
  },
}))
function AddAnswer() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { shortReadId } = useParams();
  const location = useLocation();
  const answerId = location.state?.answer?.id

  const shortReadQuery = useQuery([SHORTREADDETAIL, { shortReadId }], () =>
    http.get(`/question_and_answer/${shortReadId}`, {}).then(({ data }) => { return data; }),
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: location?.state?.answer ? {
      answerDescription: location?.state?.answer?.answerDescription,
      closing: location?.state?.answer?.closing
    } : null
  });
  const { control, handleSubmit, reset, errors } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "closing"
  });

  if (fields?.length < 1) {
    append({
      closingDescriptionText: "",
      closingDescriptionRating: 2
    })
  }

  const handleFormSubmission = async (values) => {

    setProgress(CREATE_PAYOUT_BTN, true);

    const data = {
      answerDescription: values?.answerDescription,
      closing: values?.closing,
      companyId: shortReadQuery?.data?.data?.companyId,
      questionId: shortReadId
    }

    if (location.state?.answer) {
      try {
        await http.put(`/answer/${answerId}`, data)
          .then(async (res) => {
            history.goBack();
            toast.success(t('toast.success.updatedSuccessfully'));
          });
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      try {
        await http.post(`/answer`, data)
          .then(async (res) => {
            history.goBack();
            toast.success(t('toast.success.updatedSuccessfully'));
          });
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }

    setProgress(CREATE_PAYOUT_BTN, false);
  };
  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.company.qna.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{shortReadId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container >
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Grid container>
                      <Grid item xs={4}>
                        <h5 className={classes.h5Text}>{t("admin.company.qna.questionTitle")}:</h5>
                      </Grid>
                      <Grid item xs={8}>
                        <p className={classes.pText}>{shortReadQuery?.data?.data?.title}</p>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        <h5 className={classes.h5Text}>{t("admin.company.qna.questionDesc")}:</h5>
                      </Grid>
                      <Grid item xs={8}>
                        <div className='editor' dangerouslySetInnerHTML={{ __html: shortReadQuery?.data?.data?.questionDescription }}></div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h3 className={classes.h4Text}>{t('admin.company.qna.answer')}</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container >
                          <Grid item xs={12} md={12} lg={12} xl={12}>
                            <EditorController
                              control={control}
                              name={`answerDescription`}
                              label={t('admin.company.qna.answerDesc')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {fields.map((data, index) => {
                        return (
                          <Grid item xs={12}>
                            <Grid container >
                              <Grid item xs={8} md={8} lg={8} xl={8}>
                                <EditorController
                                  control={control}
                                  name={`closing[${index}].closingDescriptionText`}
                                  label={t('admin.company.qna.closingDesc')}
                                />
                              </Grid>
                              <Grid item xs={4} md={4} lg={4} xl={4}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div style={{ margin: "30px" }}>
                                      <RatingController
                                        control={control}
                                        name={`closing[${index}].closingDescriptionRating`}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {fields?.length > 1 ?
                                      <IconButton className={classes.btn_delete} onClick={() => remove(index)}><DeleteOutlineIcon /></IconButton>
                                      : null}
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={8} md={8} lg={8} xl={8}
                            onClick={(e) => {
                              e.preventDefault();
                              append({
                                closingDescriptionText: "",
                                closingDescriptionRating: 2
                              });
                            }}
                            style={{ cursor: 'pointer' }}>
                            + {t("admin.company.qna.closingDesc")}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                {t("common.submit")}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}
export default AddAnswer;