import GroupIcon from '@mui/icons-material/Group';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SummarizeIcon from '@mui/icons-material/Summarize';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';
import i18n from 'Utils/I18n';



// export const NavLinks = [
//     { name: "الرئيسية", path: '/admin/home', current: true, Icon: HomeIcon },
//     { name: 'المقالة', path: '/admin/article', current: true, Icon: ArticleIcon },
//     { name: 'شركة', path: '/admin/company', current: true, Icon: BusinessIcon },
//     { name: 'المستخدمون', path: '/admin/users', current: true, Icon: GroupIcon },
//     { name: 'الطلبات', path: '/admin/requests', current: true, Icon: AddBoxIcon },
//     { name: 'دليل العملية', path: '/admin/guide', current: true, Icon: SummarizeIcon },
// ];
export const NavLinks = [
    { name: "Home", path: '/admin/home', current: true, Icon: HomeIcon },
    { name: 'Article', path: '/admin/article', current: true, Icon: ArticleIcon },
    { name: 'Company', path: '/admin/company', current: true, Icon: BusinessIcon },
    { name: 'Users', path: '/admin/users', current: true, Icon: GroupIcon },
    { name: 'Requests', path: '/admin/requests', current: true, Icon: AddBoxIcon },
    { name: 'Process Guide', path: '/admin/guide', current: true, Icon: SummarizeIcon },
];

// export const NavLinks = [
//     { name: i18n.t("navlinks.home"), path: '/admin/home', current: true, Icon: HomeIcon },
//     { name: i18n.t("navlinks.article"), path: '/admin/article', current: true, Icon: ArticleIcon },
//     { name: i18n.t("navlinks.company"), path: '/admin/company', current: true, Icon: BusinessIcon },
//     { name: i18n.t("navlinks.users"), path: '/admin/users', current: true, Icon: GroupIcon },
//     { name: i18n.t("navlinks.requests"), path: '/admin/requests', current: true, Icon: AddBoxIcon },
//     { name: i18n.t("navlinks.processGuide"), path: '/admin/guide', current: true, Icon: SummarizeIcon },
// ];
