import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import companyLogo from 'Assets/companyLogo.png';
import Btn from 'Components/Buttons/Btn';
// import useAuth from 'Hooks/UseAuth';
import { LocalStorage } from "Utils/LocalStorage";
import InputController from 'Components/FormControls/Controllers/InputController';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { setProgress } from 'Utils/ProgressUtils/ProgressSvc';
import yup from 'Utils/Yup';
import { toast } from 'react-toastify';
import { Ability, AbilityBuilder } from '@casl/ability';
import { ability } from 'Utils/Can';

import CryptoJS from "crypto-js";

let secretKey = "TjUM9OINvy1LH3GqA4Y9gW31SVGgQakQ2vjBktbk2c06mX8Uz0sv1dIHprzS"

const storage = new LocalStorage();

const SIGN_IN_BTN = 'SIGN_IN_BTN';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

function updateAbility(perms) {
    const { can, rules } = new AbilityBuilder(Ability);
    perms?.article?.isCreateUpdate && can("CREATE", "ARTICLE");
    perms?.article?.isDelete && can("DELETE", "ARTICLE");
    perms?.company?.isCreateUpdate && can("CREATE", "COMPANY");
    perms?.company?.isDelete && can("DELETE", "COMPANY");
    perms?.customerExecutive?.isCreateUpdate && can("CREATE", "CUSTOMER");
    perms?.customerExecutive?.isDelete && can("DELETE", "CUSTOMER");
    perms?.users?.isCreateUpdate && can("CREATE", "USER");
    perms?.users?.isDelete && can("DELETE", "USER");
    perms?.request?.isCreateUpdate && can("CREATE", "REQUEST");
    perms?.request?.isDelete && can("DELETE", "REQUEST");

    ability && ability.update(rules);
}

export default function SignInPage() {
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    const history = useHistory();
    // const auth = useAuth();

    const { t } = useTranslation();

    // const { executeRecaptcha } = useGoogleReCaptcha();

    const handleLogin = async (values) => {
        setProgress(SIGN_IN_BTN, true);
        var cipherText = CryptoJS.AES.encrypt(JSON.stringify(values), secretKey).toString();
        const data = { payload: cipherText }
        try {
            await http.post(
                '/session/login',
                { ...data },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                storage.set("user", res?.data?.data);
                storage.set("userToken", res.data.data.loginData.token);
                setProgress(SIGN_IN_BTN, false);
                history.push('/admin/home')
                updateAbility(res?.data?.data?.permission);
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
                setProgress(SIGN_IN_BTN, false);
            })

            setProgress(SIGN_IN_BTN, false);
        } catch (error) {
            // handle errors
            setProgress(SIGN_IN_BTN, false);
        }
    };

    return (
        <div className="flex min-h-screen bg-white py-12 justify-center items-center">
            <div style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                <div className="w-full max-w-sm mx-auto lg:w-96">
                    <div className="flex flex-col justify-center items-center">
                        <img className="block w-auto" src={companyLogo} alt="CX" style={{ height: "40px" }} />
                    </div>
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                        {t('signIn.signInToYourAccount')}
                    </h2>
                    <div className="mt-8">
                        <div className="mt-6">
                            <form className="space-y-4" onSubmit={handleSubmit(handleLogin)}>
                                <InputController control={control} autoComplete='off' name="email" label={t('formLabels.email')} />
                                <InputController
                                    type="password"
                                    control={control}
                                    name="password"
                                    label={t('formLabels.password')}
                                />
                                <div className="flex items-center justify-between">
                                    <Link component={RouterLink} to="/forgot-password">{t('signIn.forgotYourPassword')}</Link>
                                </div>
                                <Btn type="submit" name={SIGN_IN_BTN}>
                                    {t('signIn.signIn')}
                                </Btn>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
