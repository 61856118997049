import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  ResourcesList,
  EditorController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { SHORTREADDETAIL } from "Constants/QueriesKeys";
import ImageController from "Components/FormControls/Controllers/ImageController";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  description: Yup.string().required("required"),
  title: Yup.string().required("required"),
  attachment: Yup.array(),
});

const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function CreateUpdateNews() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { newsId } = useParams();
  const { companyId } = useParams();


  const shortReadQuery = useQuery([SHORTREADDETAIL, { newsId }], () =>
    http.get(`/news/${newsId}`, {}).then(({ data }) => data),
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: shortReadQuery?.data?.data?.title,
      description: shortReadQuery?.data?.data?.description,
      companyId: companyId
    }
  });

  const { control, handleSubmit, setError, reset, errors } = form;


  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    //convert to form data

    let formData = new FormData();

    for (var i = 0; i < values?.attachment?.length; i++) {
      formData.append("attachment", values?.attachment[i]);
    }
    
    const data = {
      title: values?.title,
      description: values?.description.replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""),
      companyId:companyId
    }


    if (newsId) {
      try {
        if (values?.attachment?.length) {
          await http.post(`/helpers/upload-image`, formData)
            .then(async (res) => {
              data['attachment'] = res?.data?.data;
              try {
                await http.put(`/news/${newsId}`, data)
                  .then(async (res) => {
                    history.push('/admin/company');
                    toast.success(t('toast.success.updatedSuccessfully'))
                  });
                reset({});
              } catch (res) {
                toast.error(res?.response?.data?.message)
              }
            });
        } else {
          data['attachment'] = shortReadQuery?.data?.data?.attachment
          try {
            await http.put(`/news/${newsId}`, data)
              .then(async (res) => {
                history.push('/admin/company');
                toast.success(t('toast.success.updatedSuccessfully'))
              });
            reset({});
          } catch (res) {
            toast.error(res?.response?.data?.message)
          }
        }
        reset({});
      } catch (res) {
            toast.error(res?.response?.data?.message)
      }
    } else {
      if (values?.attachment?.length) {
        try {
          await http.post(`/helpers/upload-image`, formData)
            .then(async (res) => {
              data['attachment'] = res?.data?.data
              })
              try {
                await http.post(`/news`, data)
                  .then(async (res) => {
                    history.goBack();
                    toast.success(t('toast.success.addedSuccessfully'))
                  }).catch(async (error) => {
                    history.goBack();
                  });
              } catch (res) {
                toast.error(res?.response?.data?.message)
              }
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      } else {
        // toast.error(t('toast.errors.headerImage'))
        try {
          await http.post(`/news`, data)
            .then(async (res) => {
              history.goBack();
              toast.success(t('toast.success.addedSuccessfully'))
            }).catch(async (error) => {
              history.goBack();
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      }
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.company.news.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{newsId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="title"
                      label={t("admin.company.news.title")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <EditorController
                      control={control}
                      name="description"
                      label={t('admin.company.create.description')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item>
                    <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.company.image")}</h6>
                    <ImageController
                      name="attachment"
                      filesLimit={1}
                      acceptedFiles
                      form={form}
                      errors={errors}
                    />
                  </Grid>
                  {/* {newsId ?
                    <Grid item xs={12}>
                      <img src={shortReadQuery?.data?.data?.attachment[0]} alt="newsImage" style={{ width: '80px' }} />
                    </Grid>
                    : null} */}
                </Grid>
              </Grid>
            </Grid>
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                {t("common.submit")}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}

export default CreateUpdateNews;
