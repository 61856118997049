import React, { useEffect, useState } from 'react';
import { Paper, TextField, InputAdornment, Grid } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { SHORTREADLIST } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DataGrid, IconBtnBase, ResourcesList } from 'Components';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { UTCtoLocal } from 'Constants/TimeZone/TimeZone';

const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};

// Component goes here
const NewsListingPage = ({ companyId }) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [searchNews, setSearchNews] = useState("");
    const perPage = 10;
    const [shortReadList, setShortReadList] = useState()
    const shortReadListRender = useQuery([SHORTREADLIST, {searchNews }], () =>
        http.get(`/news`, {
            params: {
                page: page,
                limit: perPage,
                searchString: searchNews,
                companyId: companyId
            }
        }).then(({ data }) => { setShortReadList(data?.data?.data); return (data) }),
    );
    useEffect(() => {
        http.get(`/news`, {
            params: {
                page: page,
                limit: perPage,
                searchString: searchNews,
                companyId: companyId
            }
        }).then(({ data }) => { setShortReadList(data?.data?.data)})
    }, [companyId, page])
    const rowData = []
    if (shortReadList?.length > 0) {
        for (let i = 0; i < shortReadList?.length; i++) {
            rowData[i] = {
                title: shortReadList[i]?.title,
                description: shortReadList[i]?.description,
                id: shortReadList[i]?._id,
                createdAt: shortReadList[i]?.createdAt,
                headerImage: shortReadList[i]?.attachment?.length ? shortReadList[i]?.attachment[0] : '',
            }
        }
    }

    const columns = [
        {
            field: 'title',
            headerName: t('admin.company.news.title'),
            minWidth: 200,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.title}
                    </div>
                );
            },
        },
        {
            field: 'description',
            headerName: t('admin.company.description'),
            minWidth: 250,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.description}
                    </div>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: t('admin.company.date'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {UTCtoLocal(row?.row?.createdAt, 'DD-MM-YYYY')}
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase
                            onClick={() => history.push(`/admin/company/details/${companyId}/new/${row.id}`)}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    // if (accountQuery.isLoading) {
    //     return <ResourceListSkeleton />;
    // }
    return (
        <ResourcesList
            headerProps={{
                title: t('admin.company.news.title'),
                EndSide: (
                    <div className='flex'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item >
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder={t("search.search")}
                                    id="outlined-start-adornment"
                                    name="searchNews"
                                    variant="outlined"
                                    onChange={(e) => setSearchNews(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item >
                                <IconBtnBase
                                    onClick={() => {
                                        history.push(`/admin/company/details/${companyId}/news/create`);
                                    }}
                                    size="small">
                                    <AddIcon />
                                </IconBtnBase>
                            </Grid>
                            <Grid item >
                                <IconBtnBase
                                    onClick={() => {
                                        shortReadListRender.refetch();
                                    }}
                                    size="small">
                                    <RefreshIcon />
                                </IconBtnBase>
                            </Grid>
                        </Grid>
                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={styles.tableSize}>
                        <Paper>
                            <DataGrid
                                pagination
                                rowCount={shortReadListRender?.data?.data?.totalCount}
                                paginationMode="server"
                                onPageChange={(newPage) => setPage(newPage + 1)}
                                loading={shortReadListRender?.isLoading}
                                rows={rowData}
                                columns={columns}
                                pageSize={perPage}
                            />
                        </Paper>
                    </Box>
                </div>
            }
        />
    );
};

export default NewsListingPage;
