import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { SHORTREADDETAIL } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ResourcesList } from 'Components';
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import { DataGrid, IconBtnBase } from 'Components';
import { toast } from 'react-toastify';

import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownload from 'Components/PdfDownload/FileDownload';
import { Can } from 'Utils/Can';



const styles = {
    tableSize: {
        minHeight: 'calc(100vh - 250px)',
        width: '100%',
    },
    h5Text: {
        textTransform: 'capitalize',
        color: 'red',
    }
};

const useStyles = makeStyles((theme) => ({
    h5Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)'
    },
    h4Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    pText: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,0.8)'
    },
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    },
    btn_completed: {
        cursor: "default",
        background: "none",
        '&:hover': {
            background: "none",
        },
        '&:disabled': {
            color: 'rgba(3, 166, 90,0.7)',
            background: "none",
        }
    },
    btn_pending: {
        cursor: "default",
        color: "rgb(252, 232, 55)",
        background: "none",
        '&:hover': {
            background: "none",
        }
    },
    btn_rejected: {
        cursor: "default",
        color: "rgb(255, 78, 78)",
        background: "none",
        '&:hover': {
            background: "none",
        }
    }
}))

// Component goes here
const QnADetailsPage = () => {
    const spacing = 2
    const classes = useStyles();
    const history = useHistory();
    const { companyId, questionId } = useParams();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const perPage = 10;
    const rowData = [];
    const [deletedAns, setDeletedAns] = useState()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openDeleteModal2, setOpenDeleteModal2] = useState(false)



    const shortReadListRender = useQuery([SHORTREADDETAIL, { page, perPage }], () =>
        http.get(`/answer`, {
            params: {
                page,
                limit: perPage,
                questionId
            },
        }).then(({ data }) => { return (data) }),
    );
    const shortReadQuery = useQuery([SHORTREADDETAIL, { questionId }], () =>
        http.get(`/question_and_answer/${questionId}`, {})
            .then(({ data }) => data),
    );

    if (shortReadListRender?.data?.data?.data?.length > 0) {
        for (let i = 0; i < shortReadListRender?.data?.data?.data?.length; i++) {
            rowData[i] = {
                answerDescription: shortReadListRender?.data?.data?.data[i]?.answerDescription,
                closing: shortReadListRender?.data?.data?.data[i]?.closing,
                id: shortReadListRender?.data?.data?.data[i]?._id,
                status: shortReadListRender?.data?.data?.data[i]?.status
            }
        }
    }

    const handleSoftDelete = async () => {
        try {
            await http.delete(`/question_and_answer/${questionId}`, {})
                .then(async (res) => {
                    toast.success(t('toast.success.deletedSuccessfully'))
                    history.goBack();
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
    };

    const handleSoftDeleteAnswer = async () => {
        var dataaa = {
            questionDescription: shortReadQuery?.data?.data?.questionDescription,
            title: shortReadQuery?.data?.data?.title,
        }
        dataaa["items"] = rowData.filter((item) => item.id !== deletedAns)

        try {
            await http.put(`/question_and_answer/${questionId}`, dataaa)
                .then(async (res) => {
                    toast.success(t('toast.success.deletedSuccessfully'))
                    setOpenDeleteModal2(false)
                    shortReadQuery.refetch()
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
    };
    const columns = [
        {
            field: 'answerDescription',
            headerName: t('admin.company.qna.answerDesc'),
            minWidth: 250,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <div className='editor' dangerouslySetInnerHTML={{ __html: row?.row?.answerDescription }}></div>
                    </div>
                );
            },
        },
        {
            field: 'school1',
            headerName: "Number of Closing Statements",
            minWidth: 250,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.closing?.length}
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: "status",
            minWidth: 150,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.status==="pending"?t("admin.company.create.pending"):row?.row?.status==="rejected"?t("admin.requests.rejected"):row?.row?.status==="completed"?t("admin.requests.approved"):row?.row?.status}
                    </div>
                );
            },
        },

        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 150,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase
                            className={classes.btnEdit}
                            onClick={() => history.push({
                                pathname: `/admin/company/details/${questionId}/qna/createanswer/`,
                                state: { answer: row?.row }
                            })}
                            size="large">
                            <BorderColorOutlinedIcon />
                        </IconBtnBase>
                        <IconBtnBase
                            onClick={() => history.push({
                                pathname: `/admin/company/details/question/${questionId}/qna/viewanswer/`,
                                state: { answer: row?.row }
                            })}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];
    const handleStatus = async (type, status) => {
        if (type === "question") {
            const dataUpdate = { status: status }
            try {
                await http.put(`/question_and_answer/status_update/${questionId}`, dataUpdate)
                    .then(async (res) => {
                        toast.success(t('toast.success.updatedSuccessfully'))
                        shortReadQuery.refetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        } else if (type === "awareness") {
            const dataUpdate = {
                awareness: {
                    description: shortReadQuery?.data?.data?.awareness?.description,
                    attachment: shortReadQuery?.data?.data?.awareness?.attachment,
                    status: status
                }
            }
            try {
                await http.put(`/question_and_answer/${questionId}`, dataUpdate)
                    .then(async (res) => {
                        toast.success(t('toast.success.updatedSuccessfully'))
                        shortReadQuery.refetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        } else if (type === "committee") {
            const dataUpdate = {
                committee: {
                    decisionType: shortReadQuery?.data?.data?.committee?.decisionType,
                    attachment: shortReadQuery?.data?.data?.committee?.attachment,
                    status: status
                }
            }
            try {
                await http.put(`/question_and_answer/${questionId}`, dataUpdate)
                    .then(async (res) => {
                        toast.success(t('toast.success.updatedSuccessfully'))
                        shortReadQuery.refetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        }
    };
    return (
        <>
            <Grid item xs={12}>
                <ResourcesList
                    BeforeHeader={
                        <Breadcrumbs className="mb-4">
                            <Link component={NavLink} to="/admin/article">
                                {t("admin.company.qna.title")}
                            </Link>
                            <Typography color="textPrimary">#{shortReadQuery?.data?.data?.title}</Typography>
                        </Breadcrumbs>
                    }
                    Section={
                        <div className="mt-8">
                            <Box sx={styles.tableSize}>
                                <Paper>
                                    <Card variant="outlined" style={{ padding: '20px' }}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item>
                                                <h3 className={classes.h3Text}>{t("admin.company.qna.qnaDetails")}</h3>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item>
                                                        <IconButton className={classes.btnEdit} onClick={() => history.push(`/admin/company/details/${companyId}/qna/edit/${questionId}`)} ><BorderColorOutlinedIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton className={classes.btnClose} onClick={() => setOpenDeleteModal(true)} ><DeleteOutlineOutlinedIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider style={{ margin: '10px 0' }} />
                                        <Grid container spacing={spacing}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                        <Grid container spacing={spacing}>
                                                            <Grid item xs={4}>
                                                                <h5 className={classes.h5Text}>{t("admin.company.qna.questionTitle")}:</h5>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <p className={classes.pText}>{shortReadQuery?.data?.data?.title}</p>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <h5 className={classes.h5Text}>{t("admin.company.qna.questionDesc")}:</h5>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <div className='editor' dangerouslySetInnerHTML={{ __html: shortReadQuery?.data?.data?.questionDescription }}></div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Can I="CREATE" this="REQUEST">
                                                            <Grid container spacing={2}>
                                                                {
                                                                    shortReadQuery?.data?.data?.status === "completed" ?
                                                                        <>
                                                                            <Button fullWidth color="primary" disabled className={classes.btn_completed}>{shortReadQuery?.data?.data?.status==="completed"?t("admin.requests.approved"):shortReadQuery?.data?.data?.status}</Button>
                                                                        </>
                                                                        : shortReadQuery?.data?.data?.status === "pending" ?
                                                                            <>
                                                                                <Grid item xs={6}>
                                                                                    <Button fullWidth color="primary" onClick={() => handleStatus('question', "completed")}>Accept</Button>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Button fullWidth color="error" onClick={() => handleStatus('question', "rejected")}>Reject</Button>
                                                                                </Grid>
                                                                            </>
                                                                            : shortReadQuery?.data?.data?.status === "rejected" ?
                                                                                <>
                                                                                    <Grid item xs={12}>
                                                                                        <Button fullWidth color="primary" className={classes.btn_rejected}>{shortReadQuery?.data?.data?.status==="rejected"?t("admin.requests.rejected"):shortReadQuery?.data?.data?.status}</Button>
                                                                                    </Grid>
                                                                                </>
                                                                                :
                                                                                null
                                                                }
                                                            </Grid>
                                                        </Can>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <h5 className={classes.h5Text}>{t("admin.company.qna.answer")}:</h5>
                                                    </Grid>
                                                    <Grid item xs={8} className='text-end'>
                                                        <div>
                                                            <IconBtnBase
                                                                onClick={() => {
                                                                    history.push(`/admin/company/details/${questionId}/qna/createanswer/`);
                                                                }}
                                                                size="small">
                                                                <AddIcon /><span> Add Answer</span>
                                                            </IconBtnBase>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DataGrid
                                                            pagination
                                                            rowCount={rowData?.length}
                                                            paginationMode="server"
                                                            onPageChange={(newPage) => setPage(newPage + 1)}
                                                            loading={shortReadListRender?.isLoading}
                                                            rows={rowData}
                                                            columns={columns}
                                                            pageSize={perPage}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {shortReadQuery?.data?.data?.awareness ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <Divider style={{ margin: "10px 0" }} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={10}>
                                                                <Grid container spacing={spacing}>
                                                                    <Grid item xs={12}>
                                                                        <h5 className={classes.h4Text}>{t("admin.company.qna.awarenessMaterial")}</h5>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <h5 className={classes.h5Text}>{t("admin.company.qna.description")}:</h5>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <p className={classes.pText}>
                                                                            <div className='editor' dangerouslySetInnerHTML={{ __html: shortReadQuery?.data?.data?.awareness?.description }}></div>
                                                                        </p>
                                                                    </Grid>
                                                                    {shortReadQuery?.data?.data?.awareness?.attachment?.length ?
                                                                        <Grid item xs={12}>
                                                                            <Grid container spacing={spacing}>
                                                                                <Grid item xs={12}>
                                                                                    <h5 className={classes.h5Text}>{t("admin.article.attachments")}</h5>
                                                                                </Grid>
                                                                                {shortReadQuery?.data?.data?.awareness?.attachment?.map((item) => {
                                                                                    return (
                                                                                        <Grid item xs={12} md={6} lg={4}>
                                                                                            <FileDownload file={item} />
                                                                                        </Grid>
                                                                                    )
                                                                                })}
                                                                            </Grid>
                                                                        </Grid>
                                                                        : null}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Can I="CREATE" this="REQUEST">
                                                                            <Grid container spacing={2}>
                                                                                {
                                                                                    shortReadQuery?.data?.data?.awareness?.status === "completed" ?
                                                                                        <>
                                                                                            <Button fullWidth color="primary" disabled className={classes.btn_completed}>{shortReadQuery?.data?.data?.awareness?.status === "completed" ? t("admin.requests.approved"):shortReadQuery?.data?.data?.awareness?.status}</Button>
                                                                                        </>
                                                                                        : shortReadQuery?.data?.data?.awareness?.status === "pending" ?
                                                                                            <>
                                                                                                <Grid item xs={6}>
                                                                                                    <Button fullWidth color="primary" onClick={() => handleStatus('awareness', "completed")}>Accept</Button>
                                                                                                </Grid>
                                                                                                <Grid item xs={6}>
                                                                                                    <Button fullWidth color="error" onClick={() => handleStatus('awareness', "rejected")}>Reject</Button>
                                                                                                </Grid>
                                                                                            </>
                                                                                            : shortReadQuery?.data?.data?.awareness?.status === "rejected" ?
                                                                                                <>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Button fullWidth color="primary" className={classes.btn_rejected}>{shortReadQuery?.data?.data?.awareness?.status === "rejected" ? t("admin.requests.rejected"):shortReadQuery?.data?.data?.awareness?.status}</Button>
                                                                                                    </Grid>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                }
                                                                            </Grid>
                                                                        </Can>
                                                                        <Grid item xs={12}>
                                                                            <IconBtnBase
                                                                                className={classes.btnEdit}
                                                                                onClick={() => history.push({
                                                                                    pathname: `/admin/company/details/${questionId}/qna/editawareness`,
                                                                                    state: { awareness: shortReadQuery?.data?.data?.awareness }
                                                                                })}
                                                                                size="large">
                                                                                <BorderColorOutlinedIcon />
                                                                            </IconBtnBase>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                : null}
                                            {shortReadQuery?.data?.data?.committee ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <Divider style={{ margin: "10px 0" }} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={10}>
                                                                <Grid container spacing={spacing}>
                                                                    <Grid item xs={12}>
                                                                        <h5 className={classes.h4Text}>{t("admin.company.qna.committeeDecisions")}</h5>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <h5 className={classes.h5Text}>{t("admin.company.qna.decision")}:</h5>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <p className={classes.pText}>
                                                                            {shortReadQuery?.data?.data?.committee?.decisionType==="Decision to Compensate"?t("admin.company.qna.decisionCompensate"):t('admin.company.qna.decisionRefuseCompensation')}
                                                                        </p>
                                                                    </Grid>
                                                                    {shortReadQuery?.data?.data?.committee?.attachment?.length ?
                                                                        <Grid item xs={12}>
                                                                            <Grid container spacing={spacing}>
                                                                                <Grid item xs={12}>
                                                                                    <h5 className={classes.h5Text}>{t("admin.article.attachments")}</h5>
                                                                                </Grid>
                                                                                {shortReadQuery?.data?.data?.committee?.attachment?.map((item) => {
                                                                                    return (
                                                                                        <Grid item xs={12} md={6} lg={4}>
                                                                                            <FileDownload file={item} />
                                                                                        </Grid>
                                                                                    )
                                                                                })}
                                                                            </Grid>
                                                                        </Grid>
                                                                        : null}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Can I="CREATE" this="REQUEST">
                                                                            <Grid container spacing={2}>
                                                                                {
                                                                                    shortReadQuery?.data?.data?.committee?.status === "completed" ?
                                                                                        <>
                                                                                            <Button fullWidth color="primary" disabled className={classes.btn_completed}>{shortReadQuery?.data?.data?.committee?.status === "completed" ? t("admin.requests.approved"):shortReadQuery?.data?.data?.committee?.status}</Button>
                                                                                        </>
                                                                                        : shortReadQuery?.data?.data?.committee?.status === "pending" ?
                                                                                            <>
                                                                                                <Grid item xs={6}>
                                                                                                    <Button fullWidth color="primary" onClick={() => handleStatus('committee', "completed")}>Accept</Button>
                                                                                                </Grid>
                                                                                                <Grid item xs={6}>
                                                                                                    <Button fullWidth color="error" onClick={() => handleStatus('committee', "rejected")}>Reject</Button>
                                                                                                </Grid>
                                                                                            </>
                                                                                            : shortReadQuery?.data?.data?.committee?.status === "rejected" ?
                                                                                                <>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Button fullWidth color="primary" className={classes.btn_rejected}>{shortReadQuery?.data?.data?.committee?.status === "rejected" ? t("admin.requests.rejected"):shortReadQuery?.data?.data?.committee?.status}</Button>
                                                                                                    </Grid>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                }
                                                                            </Grid>
                                                                        </Can>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <IconBtnBase
                                                                            className={classes.btnEdit}
                                                                            onClick={() => history.push({
                                                                                pathname: `/admin/company/details/${questionId}/qna/editcommittee`,
                                                                                state: { committee: shortReadQuery?.data?.data?.committee }
                                                                            })}
                                                                            size="large">
                                                                            <BorderColorOutlinedIcon />
                                                                        </IconBtnBase>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                : null}
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Box>
                            <ConfirmationDialog isOpen={openDeleteModal}   title={t("common.delete")} text={t("delete.deleteQuestion")} onReject={() => setOpenDeleteModal(false)} onResolve={handleSoftDelete} />
                            <ConfirmationDialog isOpen={openDeleteModal2}  title={t("common.delete")} text={t("delete.deleteAnswer")} onReject={() => setOpenDeleteModal2(false)} onResolve={handleSoftDeleteAnswer} />
                        </div>
                    }
                />
            </Grid>
        </>
    );
};

export default QnADetailsPage;
