import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import { useQuery } from 'react-query';
import { VIDEODETAIL } from 'Constants/QueriesKeys';
import {  http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';




const styles = {
    tableSize: {
        // minHeight: 'calc(100vh - 250px)',
        width: '100%',
    },
    h5Text: {
        textTransform: 'capitalize',
        color: 'red',
    }
};

const useStyles = makeStyles((theme) => ({
    h5Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)'
    },
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    pText: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,0.8)'
    },
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    }
}))

// Component goes here
const Home = () => {
    const spacing = 4
    const classes = useStyles();
    const { videoId } = useParams();
    const { t } = useTranslation();

    const dashboard = useQuery([VIDEODETAIL, { videoId }], () =>
        http.get(`/analytic`, {}).then(({ data }) => data),
    );


    return (
        <>
            <Grid container spacing={spacing}>
                <Grid item xs={12} sm={6}>
                    <div className="mt-8">
                        <Box >
                            <Paper>
                                <Card variant="outlined" style={{ padding: '20px', minHeight:'200px' }}>
                                    <p className={'text-center'}>{t("admin.home.h1")}</p>
                                    <Grid container spacing={spacing}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={spacing}>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <h5 className={classes.h5Text}>{t("admin.home.subh1")} :</h5>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p className={classes.pText}>{dashboard?.data?.data?.listedCompanyCount}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <h5 className={classes.h5Text}>{t('admin.home.subh2')} :</h5>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p className={classes.pText}>
                                                            {dashboard?.data?.data?.marketCompanyCount}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <h5 className={classes.h5Text}>{t('admin.home.subh3')} :</h5>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p className={classes.pText}>
                                                            {dashboard?.data?.data?.othersCompanyCount}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Paper>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="mt-8">
                        <Box sx={styles.tableSize}>
                            <Paper>
                                <Card variant="outlined" style={{ padding: '20px', minHeight:'200px' }}>
                                    <p className={'text-center'}>{t("admin.home.h2")}</p>

                                    <Grid container spacing={spacing}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={spacing}>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <h5 className={classes.h5Text}>{t("admin.home.subh4")}:</h5>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p className={classes.pText}>{dashboard?.data?.data?.pendingQuestionCount}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <h5 className={classes.h5Text}>{t('admin.home.subh5')} :</h5>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p className={classes.pText}>-
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Paper>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="mt-8">
                        <Box sx={styles.tableSize}>
                            <Paper>
                                <Card variant="outlined" style={{ padding: '20px', minHeight:'200px' }}>
                                    <p className={'text-center'}>{t("admin.home.h3")}</p>

                                    <Grid container spacing={spacing}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={spacing}>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <h5 className={classes.h5Text}>{t("admin.home.subh6")}:</h5>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p className={classes.pText}>
                                                            {dashboard?.data?.data?.teamCount}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <h5 className={classes.h5Text}>{t('admin.home.subh7')} :</h5>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p className={classes.pText}>
                                                            {dashboard?.data?.data?.customerExecutiveCount}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Paper>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="mt-8">
                        <Box sx={styles.tableSize}>
                            <Paper>
                                <Card variant="outlined" style={{ padding: '20px', minHeight:'200px' }}>
                                    <p className={'text-center'}>{t("admin.home.h4")}</p>
                                    <Grid container direction="row" spacing={spacing} justifyContent="center" alignItems="center" style={{height:'150px',textAlign:'center'}}>
                                        <Grid item xs={12}>
                                            {dashboard?.data?.data?.articleCount}
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Paper>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default Home;
