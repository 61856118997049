import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { CUSTOMEREXECUTIVELIST, USERLIST } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { DataGrid, IconBtnBase, ResourcesList } from 'Components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SearchIcon from '@mui/icons-material/Search';
import { LocalStorage } from 'Utils/LocalStorage';
import { Can } from 'Utils/Can';

const storage = new LocalStorage();




const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};

// Component goes here
const UserPage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [value, setValue] = React.useState('1');

    const [pageTeam, setPageTeam] = useState(1);
    const [pageCustomer, setPageCustomer] = useState(1);
    const perPage = 10;
    const spacing = 4
    const [teamMemberList, setTeamMemberList] = useState()
    const [customerExecutiveList, setCustomerExecutiveList] = useState()
    const [paginator1, setPaginator1] = useState()
    const [paginator2, setPaginator2] = useState()
    const [searchTeam, setSearchTeam] = useState()
    const [searchExecutive, setSearchExecutive] = useState()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const permissionArray = storage.get("user").permission



    const teamMemberRender = useQuery([USERLIST, {searchTeam }], () =>
        http.get(`/team`, {
            params: {
                page: pageTeam,
                limit: perPage,
                searchString: searchTeam
            },
            isCountOnly: false
        }).then(({ data }) => { return (data, setTeamMemberList(data?.data?.data), setPaginator1(data?.data?.totalCount)) }),
    );

    useEffect(() => {
        http.get(`/team`, {
            params: {
                page: pageTeam,
                limit: perPage,
                searchString: searchTeam
            },
        }).then(({ data }) => { setTeamMemberList(data?.data?.data); })
    }, [pageTeam]);

    const customerExecutiveRender = useQuery([CUSTOMEREXECUTIVELIST, {searchExecutive }], () =>
        http.get(`/customer_executive`, {
            params: {
                page: pageCustomer,
                limit: perPage,
                searchString: searchExecutive
            },
            isCountOnly: false
        }).then(({ data }) => { return (data, setCustomerExecutiveList(data?.data?.data), setPaginator2(data?.data?.totalCount)) }),
    );


    useEffect(() => {
        http.get(`/customer_executive`, {
            params: {
                page: pageCustomer,
                limit: perPage,
                searchString: searchExecutive
            },
        }).then(({ data }) => { setCustomerExecutiveList(data?.data?.data); })
    }, [pageCustomer]);

    const columns1 = [
        {
            field: 'name',
            headerName: t('admin.users.create.name'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: t('admin.users.create.email'),
            minWidth: 320,
            editable: false,
        },
        {
            field: 'profilePic',
            headerName: t('admin.users.create.profilePic'),
            minWidth: 180,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <img src={row?.row?.profilePic} alt=""></img>
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {/* <IconBtnBase
                            onClick={() => {
                                handleUserDetail(row?.row);
                            }}
                            size="large">
                            <DeleteOutlineOutlinedIcon />
                        </IconBtnBase> */}
                        <IconBtnBase
                            onClick={() => history.push(`${path}/team-member/details/${row.id}`)}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];
    const columns2 = [
        {
            field: 'name',
            headerName: t('admin.users.create.name'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: t('admin.users.create.email'),
            minWidth: 320,
            editable: false,
        },
        {
            field: 'profilePic',
            headerName: t('admin.users.create.profilePic'),
            minWidth: 180,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <img src={row?.row?.profilePic} alt=""></img>
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {/* <IconBtnBase
                            onClick={() => {
                                handleUserDetail(row?.row);
                            }}
                            size="large">
                            <DeleteOutlineOutlinedIcon />
                        </IconBtnBase> */}
                        <IconBtnBase
                            onClick={() => history.push(`${path}/customer-executive/details/${row.id}`)}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    var rowData1 = []

    if (teamMemberList?.length > 0) {
        for (let i = 0; i < teamMemberList?.length; i++) {
            rowData1[i] = {
                name: teamMemberList[i]?.name,
                id: teamMemberList[i]?._id,
                email: teamMemberList[i]?.email,
                profilePic: teamMemberList[i]?.profilePic,
            }
        }
    }
    var rowData2 = []

    if (customerExecutiveList?.length > 0) {
        for (let i = 0; i < customerExecutiveList?.length; i++) {
            rowData2[i] = {
                name: customerExecutiveList[i]?.name,
                id: customerExecutiveList[i]?._id,
                email: customerExecutiveList[i]?.email,
                profilePic: customerExecutiveList[i]?.profilePic,
            }
        }
    }

    // Handlers

    return (
        <ResourcesList
            headerProps={{
                title: t('admin.users.users'),
                EndSide: (
                    <div className='flex'>
                        {
                            permissionArray?.users?.isCreateUpdate ?
                            <Can I="CREATE" this="USER">
                                <IconBtnBase
                                    onClick={() => {
                                        history.push(`${path}/create`);
                                    }}
                                    size="large">
                                    <AddIcon />
                                </IconBtnBase>
                            </Can>    
                                : permissionArray?.customerExecutive?.isCreateUpdate ?
                            <Can I="CREATE" this="CUSTOMER">
                                    <IconBtnBase
                                        onClick={() => {
                                            history.push(`${path}/create`);
                                        }}
                                        size="large">
                                        <AddIcon />
                                    </IconBtnBase>
                            </Can>            

                                    : null
                        } 
                        <IconBtnBase
                            onClick={() => {
                                value === "1" ?
                                    teamMemberRender.refetch()
                                    :
                                    customerExecutiveRender.refetch()
                            }}
                            size="large">
                            <RefreshIcon />
                        </IconBtnBase>

                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label={t("admin.users.teamMember")} value="1" />
                                    <Tab label={t("admin.users.customerExecutive")} value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Box sx={styles.tableSize}>
                                    <Grid container spacing={spacing}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                placeholder={t("search.searchName")}
                                                id="outlined-start-adornment"
                                                onChange={(e) => setSearchTeam(e.target.value)}
                                                value={searchTeam}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <DataGrid
                                                    pagination
                                                    rowCount={paginator1}
                                                    paginationMode="server"
                                                    onPageChange={(newPage) => setPageTeam(newPage + 1)}
                                                    loading={teamMemberRender?.isLoading}
                                                    rows={rowData1}
                                                    columns={columns1}
                                                    pageSize={perPage}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2">
                                <Box sx={styles.tableSize}>
                                    <Grid container spacing={spacing}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                placeholder={t("search.searchName")}
                                                id="outlined-start-adornment"
                                                onChange={(e) => setSearchExecutive(e.target.value)}
                                                value={searchExecutive}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <DataGrid
                                                    pagination
                                                    rowCount={paginator2}
                                                    paginationMode="server"
                                                    onPageChange={(newPage) => setPageCustomer(newPage + 1)}
                                                    loading={customerExecutiveRender?.isLoading}
                                                    rows={rowData2}
                                                    columns={columns2}
                                                    pageSize={perPage}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
            }
        />
    );
};

export default UserPage;
