import React, { useEffect, useState } from "react";
import {
    Paper,
    TextField,
    MenuItem,
    Grid,
    InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "react-query";
import { SHORTREADLIST } from "Constants/QueriesKeys";
import { http } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DataGrid, IconBtnBase, ResourcesList } from "Components";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Can } from "Utils/Can";

const styles = {
    tableSize: {
        height: 650,
        width: "100%",
    },
};

// Component goes here
const CompanyPage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const perPage = 10;
    const [shortReadList, setShortReadList] = useState();
    const [searchCompany, setSearchCompany] = useState("");
    const [category, setCategory] = useState("");
    const [status, setStatus] = useState("");

    const shortReadListRender = useQuery(
        [SHORTREADLIST, { searchCompany, status, category }],
        () =>
            http
                .get(`/company`, {
                    params: {
                        page: page,
                        paginate: perPage,
                        status: status,
                        category: category,
                        searchString: searchCompany,
                    },
                })
                .then(({ data }) => {
                    setShortReadList(data?.data?.data);
                    return data;
                })
    );

    useEffect(() => {
        http.get(`/company`, {
            params: {
                page: page,
                paginate: perPage,
                status: status,
                category: category,
                searchString: searchCompany,
            },
        }).then(({ data }) => {
            setShortReadList(data?.data?.data);
        });
    }, [page]);

    const columns = [
        {
            field: "title",
            headerName: t("admin.company.title"),
            minWidth: 200,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.title.charAt(0).toUpperCase() +
                            row?.row?.title.slice(1)}
                    </div>
                );
            },
        },
        {
            field: "logo",
            headerName: t("admin.company.logo"),
            minWidth: 200,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <img src={row?.row?.logo} alt="" />
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: t("admin.company.status"),
            minWidth: 150,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center" style={{textTransform:"capitalize"}}>
                        {row?.row?.status==="active"? t("admin.company.create.active"):row?.row?.status==="cancelled"? t("admin.company.create.cancelled"):row?.row?.status==="pending"? t("admin.company.create.pending"):row?.row?.status}
                    </div>
                );
            },
        },
        {
            field: "category",
            headerName: t("admin.company.category"),
            minWidth: 150,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.category==="listed"? t("admin.company.create.Listed"):row?.row?.category==="market"? t("admin.company.create.Market"):row?.row?.category==="other"? t("admin.company.create.Other"):row?.row?.category}
                    </div>
                );
            },
        },
        {
            field: "code",
            headerName: t("admin.company.companyCode"),
            minWidth: 150,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.code}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: t("common.actions"),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase
                            onClick={() =>
                                history.push(`${path}/details/${row.id}`)
                            }
                            size="large"
                        >
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    const rowData = [];

    if (shortReadList?.length > 0) {
        for (let i = 0; i < shortReadList?.length; i++) {
            rowData[i] = {
                title: shortReadList[i]?.title,
                id: shortReadList[i]?._id,
                logo: shortReadList[i]?.logo,
                status: shortReadList[i]?.status,
                category: shortReadList[i]?.category,
                code: shortReadList[i]?.code || t("common.notAvailable"),

            };
        }
    }
    // if (accountQuery.isLoading) {
    //     return <ResourceListSkeleton />;
    // }

    return (
        <ResourcesList
            headerProps={{
                title: t("admin.company.title"),
                EndSide: (
                    <div className="flex">
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <TextField
                                    value={status}
                                    name="status"
                                    select
                                    label={t("admin.company.status")}
                                    onChange={(e) => setStatus(e.target.value)}
                                    size={"small"}
                                    style={{ minWidth: "120px" }}
                                >
                                    <MenuItem value={""}>
                                        {t("common.none")}
                                    </MenuItem>
                                    <MenuItem value={"pending"}>
                                        {t("admin.company.create.pending")}
                                    </MenuItem>
                                    <MenuItem value={"cancelled"}>
                                        {t("admin.company.create.cancelled")}
                                    </MenuItem>
                                    <MenuItem value={"active"}>
                                        {t("admin.company.create.active")}
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item></Grid>
                            <Grid item>
                                <TextField
                                    value={category}
                                    name="category"
                                    label={t("category.title")}
                                    select
                                    onChange={(e) =>
                                        setCategory(e.target.value)
                                    }
                                    placeholder="select Category"
                                    size={"small"}
                                    style={{ minWidth: "120px" }}
                                >
                                    <MenuItem value={""}>
                                        {t("common.none")}
                                    </MenuItem>
                                    <MenuItem value={"listed"}>
                                        {t("admin.company.create.Listed")}
                                    </MenuItem>
                                    <MenuItem value={"market"}>
                                        {t("admin.company.create.Market")}
                                    </MenuItem>
                                    <MenuItem value={"other"}>
                                        {t("admin.company.create.Other")}
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item></Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder={t("search.searchCompany")}
                                    id="outlined-start-adornment"
                                    onChange={(e) =>
                                        setSearchCompany(e.target.value)
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Can I="CREATE" this="COMPANY">
                                    <IconBtnBase
                                        onClick={() => {
                                            history.push(`${path}/create`);
                                        }}
                                        size="small"
                                    >
                                        <AddIcon />
                                    </IconBtnBase>
                                </Can>
                            </Grid>
                            <Grid item>
                                <IconBtnBase
                                    onClick={() => {
                                        shortReadListRender.refetch();
                                    }}
                                    size="small"
                                >
                                    <RefreshIcon />
                                </IconBtnBase>
                            </Grid>
                        </Grid>
                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={styles.tableSize}>
                        <Paper>
                            <DataGrid
                                pagination
                                rowCount={
                                    shortReadListRender?.data?.data?.totalCount
                                }
                                paginationMode="server"
                                onPageChange={(newPage) => setPage(newPage + 1)}
                                loading={shortReadListRender?.isLoading}
                                rows={rowData}
                                columns={columns}
                                pageSize={perPage}
                            />
                        </Paper>
                    </Box>
                </div>
            }
        />
    );
};

export default CompanyPage;
