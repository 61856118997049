import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Divider, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  ResourcesList,
  EditorController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { ARTICLECATEGORYSUBCATEGORY, ARTICLEDETAIL } from "Constants/QueriesKeys";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import FileController from "Components/FormControls/Controllers/FileController";
import FileDownload from "Components/PdfDownload/FileDownload";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  description: Yup.string().required("required"),
  title: Yup.string().required("required"),
  category: Yup.string().required("required"),
});

const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function CreateUpdateArticle() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { articleId } = useParams();
  const [attachmentss, setAttachmentss] = useState()
  const [categoryList, setCategoryList] = useState()
  const [enabled, setEnabled] = useState(true);

  const [subCategoryList, setSubCategoryList] = useState()
  const [selectedSubCategory, setSelectedSubCategory] = useState("")

  // const magazineDetailsQuery = useQuery([MAGAZINEDETAIL, { magazineId }], () =>
  //   http.get(`/magazine/${magazineId}`, {}).then(({ data }) => {
  //      if (data?.data) form.reset(HookFormDefaultValue(data?.data)); setEnabled(false); return data; })
  //   , {
  //     enabled: magazineId !== undefined ? enabled ? true : false : false,
  //   });

  const articleQuery = useQuery([ARTICLEDETAIL, { articleId }], () =>
    articleId ?
      http.get(`/article/${articleId}`, {}).then(({ data }) => {
        setEnabled(false);
        return (data,
          reset({
            description: data?.data?.description,
            title: data?.data?.title,
            category: data?.data?.category,
          }
          ),
          setSelectedSubCategory(data?.data?.subCategory)
          , setAttachmentss(data?.data?.attachment))
      })
      : null,
    {
      enabled: articleId !== undefined ? enabled ? true : false : false,
    },
  );

  const form = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   description: articleQuery?.data?.data?.description,
    //   title: articleQuery?.data?.data?.title,
    //   category: articleQuery?.data?.data?.category,
    // }
  });
  const { control, handleSubmit, setError, reset, errors } = form;

  const selectedCategory = useWatch({
    name: "category",
    control
  })


  const articleCategory = useQuery([ARTICLECATEGORYSUBCATEGORY], () =>
    http.post(`/article/category_subcategory`, {
      type: "1",
    }).then(({ data }) => { return (data, setCategoryList(data?.data)) }),
  );

  const articleSubCategory = useQuery([ARTICLECATEGORYSUBCATEGORY, { selectedCategory }], () =>
    http.post(`/article/category_subcategory`, {
      type: 2,
      category: selectedCategory
    }).then(({ data }) => { return (data, setSubCategoryList(data?.data)) }),
  );




  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    //convert to form data

    let formData = new FormData();

    for (var i = 0; i < values.attachment?.length; i++) {
      formData.append("attachment", values.attachment[i]);
    }
    const data = {
      title: values?.title,
      category: selectedCategory,
      description: values?.description.replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""),
    }
    if (selectedSubCategory) {
      data['subCategory']= selectedSubCategory
    }

    if (articleId) {
      try {
        if (values.attachment?.length) {
          try {
            await http.post(`/helpers/upload-file`, formData)
              .then(async (res) => {
                data['attachment'] = res?.data?.data
                try {
                  await http.put(`/article/${articleId}`, data)
                    .then(async (res) => {
                      history.push('/admin/article');
                      toast.success(t('toast.success.updatedSuccessfully'))
                    });
                  reset({});
                } catch (error) {
                  toast.error(res?.response?.data?.message)
                }
              });
          } catch (res) {
            toast.error(res?.response?.data?.message)
          }
        } else {
          data['attachment'] = attachmentss
          try {
            await http.put(`/article/${articleId}`, data)
              .then(async (res) => {
                history.push('/admin/article');
                toast.success(t('toast.success.updatedSuccessfully'))
              });
            reset({});
          } catch (res) {
            toast.error(res?.response?.data?.message)
          }
        }
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    } else {
      if (values.attachment?.length) {
        try {
          await http.post(`/helpers/upload-file`, formData)
            .then(async (res) => {
              data['attachment'] = res?.data?.data
              try {
                await http.post(`/article`, data)
                  .then(async (res) => {
                    history.push('/admin/article');
                    toast.success(t('toast.success.addedSuccessfully'))
                  });
                reset({});
              } catch (res) {
                toast.error(res?.response?.data?.message)
              }
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      } else {
        try {
          await http.post(`/article`, data)
            .then(async (res) => {
              history.push('/admin/article');
              toast.success(t('toast.success.addedSuccessfully'))
            });
          reset({});
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      }
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };
  const categoryArray = ["أعمال دون ترخيص", "دلـــيــــل الإجــــــراءات الــمــبـــســــط"]

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.article.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{articleId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="title"
                      label={t("admin.article.create.title")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputController
                  control={control}
                  name="category"
                  size="small"
                  select
                  label={t("admin.article.category")}
                >
                  {categoryArray?.map((item) => {
                    return (
                      <MenuItem value={item}>{item}</MenuItem>
                    )
                  })}
                </InputController>
              </Grid>
              <Grid item xs={12}>
                <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.article.subCategory")}</h6>
                <Autocomplete
                  freeSolo
                  size="small"
                  options={subCategoryList?.map((option) => option)}
                  renderInput={(params) => <TextField {...params}
                    value={selectedSubCategory}
                    onChange={(event, newValue) => { setSelectedSubCategory(event?.target?.value) }}
                  />}
                  value={selectedSubCategory}
                  onChange={(event, newValue) => { setSelectedSubCategory(newValue) }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <EditorController
                      control={control}
                      name="description"
                      label={t('admin.article.create.description')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item>
                    <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.article.attachments")}</h6>
                    <FileController
                      name="attachment"
                      filesLimit={5}
                      acceptedFiles
                      form={form}
                      errors={errors}
                    />
                  </Grid>
                  {attachmentss?.length ?
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <p style={{ fontSize: "12px", color: "rgba(0,0,0,0.5)" }}>Previous attachments</p>
                        </Grid>
                        {attachmentss?.map((item) => {
                          return (
                            <Grid item xs={12} md={4} lg={4}>
                              <FileDownload file={item} />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                    : null}
                </Grid>
              </Grid>
            </Grid>
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>{t("common.submit")}</Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}

export default CreateUpdateArticle;
