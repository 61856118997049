import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { SHORTREADDETAIL } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ResourcesList } from 'Components';
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';
import FileDownload from 'Components/PdfDownload/FileDownload';
import { Can } from 'Utils/Can';



const styles = {
    tableSize: {
        minHeight: 'calc(100vh - 250px)',
        width: '100%',
    },
    h5Text: {
        textTransform: 'capitalize',
        color: 'red',
    }
};

const useStyles = makeStyles((theme) => ({
    h5Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)'
    },
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    pText: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,0.8)'
    },
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    }
}))

// Component goes here
const ArticleDetailPage = () => {
    const spacing = 4
    const classes = useStyles();
    const history = useHistory();
    const { articleId } = useParams();
    const { t } = useTranslation();
    const [openDeleteModal, setOpenDeleteModal] = useState(false)


    const shortReadQuery = useQuery([SHORTREADDETAIL, { articleId }], () =>
        // http.get(`/article`, {}).then(({ data }) => data),
        http.get(`/article/${articleId}`, {}).then(({ data }) => data),
    );

    const [detailsData, setDetailsData] = useState()

    useEffect(() => {
        setDetailsData(shortReadQuery?.data?.data)
    }, [shortReadQuery])

    const handleSoftDelete = async () => {
        try {
            await http.delete(`/article/${articleId}`, {})
                .then(async (res) => {
                    toast.success(t('toast.success.deletedSuccessfully'))
                    history.push('/admin/article');
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <ResourcesList
                    BeforeHeader={
                        <Breadcrumbs className="mb-4">
                            <Link component={NavLink} to="/admin/article">
                                {t("admin.article.title")}
                            </Link>
                            <Typography color="textPrimary">#{articleId}</Typography>
                        </Breadcrumbs>
                    }
                    Section={
                        <div className="mt-8">
                            <Box sx={styles.tableSize}>
                                <Paper>
                                    <Card variant="outlined" style={{ padding: '20px' }}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item>
                                                <h3 className={classes.h3Text}>{t("admin.article.articleDetails")}</h3>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item>
                                                        <Can I="CREATE" this="ARTICLE">
                                                            <IconButton className={classes.btnEdit} onClick={() => history.push(`/admin/article/edit/${articleId}`)} ><BorderColorOutlinedIcon /></IconButton>
                                                        </Can>
                                                    </Grid>
                                                    <Grid item>
                                                        <Can I="DELETE" this="ARTICLE">
                                                            <IconButton className={classes.btnClose} onClick={() => setOpenDeleteModal(true)} ><DeleteOutlineOutlinedIcon /></IconButton>
                                                        </Can>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider style={{ margin: '10px 0' }} />
                                        <Grid container spacing={spacing}>
                                            <Grid item xs={10}>
                                                <Grid container spacing={spacing}>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                                <h5 className={classes.h5Text}>{t("admin.article.create.title")}:</h5>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <p className={classes.pText}>{detailsData?.title}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                                <h5 className={classes.h5Text}>{t('admin.article.category')} :</h5>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <p className={classes.pText}>
                                                                    {detailsData?.category}
                                                                </p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {detailsData?.subCategory ?
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={2}>
                                                                    <h5 className={classes.h5Text}>{t('admin.article.subCategory')} :</h5>
                                                                </Grid>
                                                                <Grid item xs={10}>
                                                                    <p className={classes.pText}>
                                                                        {detailsData?.subCategory ? detailsData?.subCategory : "-"}
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        : null}
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                                <h5 className={classes.h5Text}>{t("admin.article.description")}:</h5>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <div className='editor' dangerouslySetInnerHTML={{ __html: detailsData?.description }}></div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {detailsData?.attachment?.length ?
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <h5 className={classes.h5Text}>{t("admin.article.attachments")}</h5>
                                                        </Grid>
                                                        {detailsData?.attachment?.map((item) => {
                                                            return (
                                                                <Grid item xs={12} md={6} lg={4}>
                                                                    <FileDownload file={item} />
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                                : null}
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Box>
                            <ConfirmationDialog isOpen={openDeleteModal} title={t("common.delete")} text={t("delete.deleteArticle")} onReject={() => setOpenDeleteModal(false)} onResolve={handleSoftDelete} />
                        </div>
                    }
                />
            </Grid>
        </>
    );
};

export default ArticleDetailPage;
