import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { CUSTOMEREXECUTIVELIST, USERLIST } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { DataGrid, IconBtnBase, ResourcesList } from 'Components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SearchIcon from '@mui/icons-material/Search';
// import { LocalStorage } from 'Utils/LocalStorage';
import { UTCtoLocal } from 'Constants/TimeZone/TimeZone';

// const storage = new LocalStorage();




const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};

// Component goes here
const RequestPage = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const [value, setValue] = React.useState('1');

    const [pageTeam, setPageTeam] = useState(1);
    const [pageCustomer, setPageCustomer] = useState(1);
    const perPage = 10;
    const spacing = 4
    const [questionsList, setQuestionsList] = useState()
    const [answersList, setAnswersList] = useState()
    const [paginator1, setPaginator1] = useState()
    const [paginator2, setPaginator2] = useState()
    const [searchQuestion, setSearchQuestion] = useState()
    const [searchAnswer, setSearchAnswer] = useState()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const permissionArray = storage.get("user").permission


    const QuestionListRender = useQuery([USERLIST, { searchQuestion }], () =>
        http.get(`/question_and_answer`, {
            params: {
                page: pageTeam,
                limit: perPage,
                searchString: searchQuestion,
                status: ['pending', 'rejected']
            },
        }).then(({ data }) => { return (data, setQuestionsList(data?.data?.data), setPaginator1(data?.data?.totalCount)) }),
    );

    useEffect(() => {
        http.get(`/question_and_answer`, {
            params: {
                page: pageTeam,
                limit: perPage,
                searchString: searchQuestion,
                status: ['pending', 'rejected']
            },
        }).then(({ data }) => { setQuestionsList(data?.data?.data); })
    }, [pageTeam]);

    const AnswerListRender = useQuery([CUSTOMEREXECUTIVELIST, {searchAnswer }], () =>
        http.get(`/answer`, {
            params: {
                page: pageCustomer,
                limit: perPage,
                searchString: searchAnswer,
                status: ['pending', 'rejected']
            },
            isCountOnly: false
        }).then(({ data }) => { return (data, setAnswersList(data?.data?.data), setPaginator2(data?.data?.totalCount)) }),
    );

    useEffect(() => {
        http.get(`/answer`, {
            params: {
                page: pageCustomer,
                limit: perPage,
                searchString: searchAnswer,
                status: ['pending', 'rejected']
            },
        }).then(({ data }) => { setAnswersList(data?.data?.data); })
    }, [pageCustomer]);

    const columns1 = [
        {
            field: 'title',
            headerName: t('admin.company.qna.question'),
            minWidth: 200,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.title}
                    </div>
                );
            },
        },
        {
            field: 'questionDescription',
            headerName: t('admin.company.qna.questionDesc'),
            minWidth: 250,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <div className='editor' dangerouslySetInnerHTML={{ __html: row?.row?.questionDescription }}></div>
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: t('admin.company.qna.status'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center" style={{textTransform:"capitalize"}}>
                        {row?.row?.status==="pending"?t("admin.company.create.pending"):row?.row?.status==="rejected"?t("admin.requests.rejected"):row?.row?.status}
                    </div>
                );
            },
        },
        {
            field: 'createdBy',
            headerName: t('admin.company.qna.requestBy'),
            minWidth: 140,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.createdBy}
                    </div>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: t('admin.company.date'),
            minWidth: 120,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.createdAt}
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase
                            onClick={() => history.push(`/admin/requests/details/${row?.row?.companyId}/qnas/${row.id}`)}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];
    const columns2 = [

        {
            field: 'questionTitle',
            headerName: t('admin.company.qna.question'),
            minWidth: 170,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.questionTitle}
                    </div>
                );
            },
        },
        {
            field: 'questionDescription',
            headerName: t('admin.company.qna.questionDesc'),
            minWidth: 170,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <div className='editor' dangerouslySetInnerHTML={{ __html: row?.row?.questionDescription }}></div>
                    </div>
                );
            },
        },
        {
            field: 'answerDescription',
            headerName: t('admin.company.qna.answer'),
            minWidth: 170,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <div className='editor' dangerouslySetInnerHTML={{ __html: row?.row?.answerDescription }}></div>
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: t('admin.company.qna.status'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center" style={{textTransform:"capitalize"}}>
                        {row?.row?.status==="pending"?t("admin.company.create.pending"):row?.row?.status==="rejected"?t("admin.requests.rejected"):row?.row?.status}
                    </div>
                );
            },
        },
        {
            field: 'createdBy',
            headerName: t('admin.company.qna.requestBy'),
            minWidth: 120,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.createdBy}
                    </div>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: t('admin.company.date'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.createdAt}
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 70,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase
                            onClick={() => history.push({
                                pathname: `/admin/requests/details/question/${row?.row?.questionId}/qna/requestviewanswer/`,
                                state: { answer: row?.row, type: "answer" }
                            })}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    var rowData1 = []

    if (questionsList?.length > 0) {
        for (let i = 0; i < questionsList?.length; i++) {
            rowData1[i] = {
                title: questionsList[i]?.title,
                questionDescription: questionsList[i]?.questionDescription,
                id: questionsList[i]?._id,
                createdBy: questionsList[i]?.customerExecutiveId?.name,
                createdAt: UTCtoLocal(questionsList[i]?.createdAt, 'DD-MM-YYYY'),
                items: questionsList[i]?.items?.length ? questionsList[i]?.items[0] : '',
                companyId: questionsList[i]?.companyId,
                status: questionsList[i]?.status
            }
        }
    }

    var rowData2 = []

    if (answersList?.length) {
        for (let i = 0; i < answersList?.length; i++) {
            rowData2[i] = {
                id: answersList[i]?._id,
                companyId: answersList[i]?.companyId,
                questionId: answersList[i]?.questionId?._id,
                questionTitle: answersList[i]?.questionId?.title,
                questionDescription: answersList[i]?.questionId?.questionDescription,
                answerDescription: answersList[i]?.answerDescription,
                status: answersList[i]?.status,
                closing: answersList[i].closing,
                createdBy: answersList[i]?.customerExecutiveId?.name,
                createdAt: UTCtoLocal(answersList[i]?.createdAt, 'DD-MM-YYYY'),
            }
        }
    }
    // Handlers

    return (
        <ResourcesList
            headerProps={{
                title: t('admin.requests.requests'),
                EndSide: (
                    <div className='flex'>
                        <IconBtnBase
                            onClick={() => {
                                value === "1" ?
                                    QuestionListRender.refetch()
                                    :
                                    AnswerListRender.refetch()
                            }}
                            size="large">
                            <RefreshIcon />
                        </IconBtnBase>

                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label={t("admin.requests.questions")} value="1" />
                                    <Tab label={t("admin.requests.answers")} value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Box sx={styles.tableSize}>
                                    <Grid container spacing={spacing}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                placeholder={t("search.search")}
                                                id="outlined-start-adornment"
                                                onChange={(e) => setSearchQuestion(e.target.value)}
                                                value={searchQuestion}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <DataGrid
                                                    pagination
                                                    rowCount={paginator1}
                                                    paginationMode="server"
                                                    onPageChange={(newPage) => setPageTeam(newPage + 1)}
                                                    loading={QuestionListRender?.isLoading}
                                                    rows={rowData1}
                                                    columns={columns1}
                                                    pageSize={perPage}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2">
                                <Box sx={styles.tableSize}>
                                    <Grid container spacing={spacing}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                placeholder={t("search.search")}
                                                id="outlined-start-adornment"
                                                onChange={(e) => setSearchAnswer(e.target.value)}
                                                value={searchAnswer}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <DataGrid
                                                    pagination
                                                    rowCount={paginator2}
                                                    paginationMode="server"
                                                    onPageChange={(newPage) => setPageCustomer(newPage + 1)}
                                                    loading={AnswerListRender?.isLoading}
                                                    rows={rowData2}
                                                    columns={columns2}
                                                    pageSize={perPage}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
            }
        />
    );
};

export default RequestPage;
