import React, { useEffect, useState } from 'react';
import { Paper, TextField, InputAdornment, Grid, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { SHORTREADLIST } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DataGrid, IconBtnBase, ResourcesList } from 'Components';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { UTCtoLocal } from 'Constants/TimeZone/TimeZone';

const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};

// Component goes here
const QnAListingPage = ({ companyId }) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [searchQnA, setSearchQnA] = useState("");
    const perPage = 10;
    const [shortReadList, setShortReadList] = useState("");
    const [status, setStatus] = useState("");
    
    const shortReadListRender = useQuery([SHORTREADLIST, {searchQnA, status }], () =>
        http.get(`/question_and_answer`, {
            params: {
                page: page,
                limit: perPage,
                status,
                searchString: searchQnA,
                companyId:companyId
            }
        }).then(({ data }) => { setShortReadList(data?.data?.data);return (data) }),
    );

    useEffect(() => {
        http.get(`/question_and_answer`, {
            params: {
                page: page,
                limit: perPage,
                status,
                searchString: searchQnA,
                companyId:companyId
            }
        }).then(({ data }) => { setShortReadList(data?.data?.data); })
    }, [page]);


    const rowData = []
    if (shortReadList?.length > 0) {
        for (let i = 0; i < shortReadList?.length; i++) {
            rowData[i] = {
                title: shortReadList[i]?.title,
                questionDescription: shortReadList[i]?.questionDescription,
                id: shortReadList[i]?._id,
                createdAt:  UTCtoLocal(shortReadList[i]?.createdAt, 'DD-MM-YYYY'),
                items: shortReadList[i]?.items?.length ? shortReadList[i]?.items[0] : '',
                status: shortReadList[i]?.status,
            }
        }
    }

    const columns = [

        {
            field: 'title',
            headerName: t('admin.company.qna.question'),
            minWidth: 200,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.title}
                    </div>
                );
            },
        },
        {
            field: 'questionDescription',
            headerName: t('admin.company.qna.questionDesc'),
            minWidth: 250,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <div className='editor' dangerouslySetInnerHTML={{ __html: row?.row?.questionDescription }}></div>
                        {/* {row?.row?.questionDescription} */}
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: t('admin.company.qna.status'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.status}
                    </div>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: t('admin.company.date'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.createdAt}
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase
                            onClick={() => history.push(`/admin/company/details/${companyId}/qnas/${row.id}`)}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    // if (accountQuery.isLoading) {
    //     return <ResourceListSkeleton />;
    // }
    return (
        <ResourcesList
            headerProps={{
                title: t('admin.company.qna.title'),
                EndSide: (
                    <div className='flex'>
                         <Grid container direction="row" spacing={2}>
                         <Grid item >
                                <TextField
                                    value={status}
                                    name="status"
                                    select
                                    label={t("admin.company.status")}
                                    onChange={(e) => setStatus(e.target.value)}
                                    size={'small'}
                                    style={{ minWidth: "120px" }}
                                >
                                    <MenuItem value={''}>None</MenuItem>
                                    <MenuItem value={'pending'}>Pending</MenuItem>
                                    <MenuItem value={'rejected'}>Rejected</MenuItem>
                                    <MenuItem value={'completed'}>Completed</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item >
                          <TextField
                            fullWidth
                            size="small"
                            placeholder={t("search.search")}
                            id="outlined-start-adornment"
                            name="searchQnA" 
                            variant="outlined"
                            onChange={(e)=>setSearchQnA(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            }}
                        />
                        </Grid>
                        <Grid item >
                        <IconBtnBase
                            onClick={() => {
                                history.push(`/admin/company/details/${companyId}/qna/create`);
                            }}
                            size="small">
                            <AddIcon />
                        </IconBtnBase>
                        </Grid>
                        <Grid item >
                        <IconBtnBase
                            onClick={() => {
                                shortReadListRender.refetch();
                            }}
                            size="small">
                            <RefreshIcon />
                        </IconBtnBase>
                        </Grid>
                        </Grid>
                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={styles.tableSize}>
                        <Paper>
                                <DataGrid
                                    pagination
                                    rowCount={shortReadListRender?.data?.data?.totalCount}
                                    paginationMode="server"
                                    onPageChange={(newPage) => setPage(newPage + 1)}
                                    loading={shortReadListRender?.isLoading}
                                    rows={rowData}
                                    columns={columns}
                                    pageSize={perPage}
                                />
                        </Paper>
                    </Box>
                </div>
            }
        />
    );
};

export default QnAListingPage;
