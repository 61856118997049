import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, Card, Divider, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ResourcesList } from 'Components';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import yup from 'Utils/Yup';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import { ARTICLECATEGORYSUBCATEGORY } from 'Constants/QueriesKeys';
import { toast } from 'react-toastify';
import ImageViewModal from './components/ImageViewModal';



const useStyles = makeStyles((theme) => ({
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    addIcon: {
        fontSize: '24px',
        color: 'rgba(53, 50, 255, 0.6)',
        cursor: 'pointer',
        '&:hover': {
            color: 'rgba(0, 0, 255, 0.7)',
        }
    },
    deleteIcon: {
        fontSize: '24px',
        color: 'rgba(255, 100, 100, 0.6)',
        cursor: 'pointer',
        '&:hover': {
            color: 'rgba(255, 100, 100, 0.9)',
        }
    },
}))

const EditProcessGuide = () => {

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [prevAttachment, setPrevAttachment] = useState()
    const [prevImage, setPrevImage] = useState();
    const [modalOpen, setModalOpen] = useState(false);

  const categoryArray = ["أعمال دون ترخيص", "دلـــيــــل الإجــــــراءات الــمــبـــســــط"]




    const articleSubCategory = useQuery([ARTICLECATEGORYSUBCATEGORY, {}], () =>
        http.post(`/article/category_subcategory`, {
            type: 2,
            category: categoryArray[1]
        }).then(({ data }) => { return (data) }),
    );

    useEffect(() => {
        http.get(`/process_guide`, {}).then(({ data }) => {
            setPrevAttachment(data?.data?.items);
            setPrevImage(data?.data?.attachment[0]?.src)
        })
    }, [])

    const schema = yup.object({

    });
    const form = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit, setError, errors, watch } = form;

    const handleValueChange = (index, newValue, name) => {
        let temp = prevAttachment;
        temp[index][name] = newValue
        setPrevAttachment([...temp])
    }
    const addNewEntry = () => {
        let temp = prevAttachment;
        temp.push({ number: '', subCategory: '' })
        setPrevAttachment([...temp])
    }

    const deleteEntry = (index) => {
        let temp = prevAttachment;
        temp.splice(index, 1)
        setPrevAttachment([...temp])
    }

    const handleFormSubmission = async (values) => {
        let formData = new FormData();
        for (var i = 0; i < values.selectImage?.length; i++) {
            formData.append("attachment", values.selectImage[i]);
        }
        // for (let j = 0; j < prevAttachment.length; j++) {
        //     prevAttachment[j]["category"] = "دلـــيــــل الإجــــــراءات الــمــبـــســــط";

        // }
        const data = {
            items: prevAttachment
        }
        if (values.selectImage?.length) {
            try {
                await http.post(`/helpers/upload-file`, formData)
                    .then(async (res) => {
                        data['attachment'] = res?.data?.data
                        try {
                            await http.put(`/process_guide/61e8e917f7a5ef9dfd1c63a3`, data)
                                .then(async (res) => {
                                    history.push('/admin/guide');
                                    toast.success(t('toast.success.updatedSuccessfully'))
                                });
                            // reset({});
                        } catch (res) {
                            toast.error(res?.response?.data?.message)
                        }
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        } else {
            try {
                await http.put(`/process_guide/61e8e917f7a5ef9dfd1c63a3`, data)
                    .then(async (res) => {
                        history.push('/admin/guide');
                        toast.success(t('toast.success.updatedSuccessfully'))
                    });
                // reset({});
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        }
    }
    return (
        <ResourcesList
            BeforeHeader={
                <>
                    <Typography variant="h6" color="textPrimary">
                        {t('admin.guide.processGuide')}
                    </Typography>
                </>
            }
            Section={
                <Card variant="outlined" className="p-5">
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <h3 className={classes.h3Text}>{t("common.updateDetails")}</h3>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: '10px 0' }} />
                    <form
                        onSubmit={handleSubmit(handleFormSubmission)}
                    >
                        <Grid container spacing={2} style={{ position: 'sticky', top: '0', marginBottom: '10px' }}>
                            <Grid item xs={8}>
                                <h6 className="labelForm" style={{ marginBottom: '15px' }}>Select Image</h6>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <ImageController
                                            name="selectImage"
                                            filesLimit={1}
                                            acceptedFiles
                                            form={form}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <h6 className="labelForm" style={{ marginBottom: '15px' }}>Selected Image</h6>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tooltip title="tap to view">
                                            <img src={watch("selectImage")?.length > 0 ? watch("selectImage")[0]?.preview : prevImage} onClick={() => setModalOpen(true)} style={{ height: "80px", cursor: "pointer" }} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' spacing={2} className={classes.formField}>
                            <Grid item xs={12} md={6} xl={5} style={{ marginTop: "20px" }}>
                                <Grid container>
                                    <Grid container alignItems="center" spacing={2} style={{ marginBottom: "20px" }}>
                                        <Grid item lg={2} style={{ margin: "4px" }}>
                                            <p>Category:</p>
                                        </Grid>
                                        <Grid item lg={7} style={{ margin: "4px" }}>
                                            <TextField
                                                // select
                                                fullWidth
                                                value={categoryArray[1]}
                                                size="small"
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={2} style={{ margin: "4px" }}>
                                        Number
                                    </Grid>
                                    <Grid item lg={7} style={{ margin: "4px" }}>
                                        Sub Category
                                    </Grid>
                                    {prevAttachment?.map((item, index) => {
                                        return (
                                            <Grid item xs={12} key={item.id}>
                                                <Grid container spacing={0}>
                                                    <Grid item lg={2} style={{ margin: "4px" }}>
                                                        <TextField
                                                            value={item?.number}
                                                            type="number"
                                                            size="small"
                                                            onChange={(event, newValue) => { handleValueChange(index, parseInt(event.target.value), "number") }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={7} style={{ margin: "4px" }}>
                                                        <Autocomplete
                                                            size="small"
                                                            options={articleSubCategory?.data?.data ? articleSubCategory?.data?.data?.map((option) => option) : []}
                                                            renderInput={(params) => <TextField {...params}
                                                            />}
                                                            value={item?.subCategory}
                                                            onChange={(event, newValue) => { handleValueChange(index, newValue, "subCategory") }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={2} style={{ margin: "4px" }}>
                                                        <Grid container justifyContent='space-between' alignItems='center' style={{ height: '100%' }}>
                                                            {prevAttachment?.length > 1 ?
                                                                <Grid item><DeleteOutlineIcon type="button" onClick={() => deleteEntry(index)} className={classes.deleteIcon} /></Grid>
                                                                : <Grid item></Grid>}
                                                            {prevAttachment?.length === index + 1 ?
                                                                <Grid item><AddIcon type="button" onClick={addNewEntry} className={classes.addIcon} /></Grid>
                                                                : <Grid item></Grid>}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider fullwidth style={{ margin: '14px 0' }} />
                        <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid item>
                                <Button type='submit' disabled={false} className={classes.btn_amt}>Save</Button>
                            </Grid>
                        </Grid>
                    </form>
                    <ImageViewModal open={modalOpen} handleClose={() => setModalOpen(false)} item={watch("selectImage")?.length > 0 ? watch("selectImage")[0]?.preview : prevImage} />
                </Card>
            }
        />
    );
};

export default EditProcessGuide;
