import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
    Btn,
    InputController,
    setProgress,
    RadioGroupController,
    ResourcesList,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { SHORTREADDETAIL } from "Constants/QueriesKeys";
import ImageController from "Components/FormControls/Controllers/ImageController";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
    logo: Yup.array(),
    title: Yup.string().required("required"),
    // status: Yup.string().required("required"),
    category: Yup.string().required("required"),
});

const useStyles = makeStyles((theme) => ({
    h3Text: {
        textTransform: "capitalize",
        fontSize: "24px",
        color: "rgb(3, 166, 90)",
        fontWeight: "500",
    },
}));

let selectedCategory;

function CreateUpdateCompany() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { shortReadId } = useParams();
    const [enabled, setEnabled] = useState(true);

    const shortReadQuery = useQuery(
        [SHORTREADDETAIL, { shortReadId }],
        () =>
            http.get(`/company/${shortReadId}`, {}).then(({ data }) => {
                setEnabled(false);
                return data;
            }),
        {
            enabled:
                shortReadId !== undefined ? (enabled ? true : false) : false,
        }
    );

    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            title: shortReadQuery?.data?.data?.title,
            status:
                selectedCategory !== "other"
                    ? shortReadQuery?.data?.data?.status
                    : "",
            category: shortReadQuery?.data?.data?.category,
            code: shortReadQuery?.data?.data?.code,
        },
    });

    const { control, handleSubmit, setError, reset, errors, watch } = form;

    selectedCategory = watch("category");

    // const selectedFor = useWatch({
    //   name: "viewers",
    //   control
    // })?.find((item) => item === "Student") ? true : false

    const handleFormSubmission = async (values) => {
        setProgress(CREATE_PAYOUT_BTN, true);
        //convert to form data

        let formData = new FormData();

        for (var i = 0; i < values?.logo?.length; i++) {
            formData.append("logo", values?.logo[i]);
        }
        const data = {
            title: values?.title,
            status: values?.status,
            category: values?.category,
            code: values?.code,
        };

        if (shortReadId) {
            try {
                if (values?.logo?.length) {
                    await http
                        .post(`/helpers/upload-image`, formData)
                        .then(async (res) => {
                            data["logo"] = res?.data?.data[0]?.src;
                            try {
                                await http
                                    .put(`/company/${shortReadId}`, data)
                                    .then(async (res) => {
                                        history.push("/admin/company");
                                        toast.success(
                                            t(
                                                "toast.success.updatedSuccessfully"
                                            )
                                        );
                                    });
                                reset({});
                            } catch (res) {
                                toast.error(res?.response?.data?.message);
                            }
                        });
                } else {
                    data["logo"] = shortReadQuery?.data?.data?.logo;
                    try {
                        await http
                            .put(`/company/${shortReadId}`, data)
                            .then(async (res) => {
                                history.push("/admin/company");
                                toast.success(
                                    t("toast.success.updatedSuccessfully")
                                );
                            });
                        reset({});
                    } catch (res) {
                        toast.error(res?.response?.data?.message);
                    }
                }
            } catch (res) {
                toast.error(res?.response?.data?.message);
            }
        } else {
            if (values?.logo?.length) {
                try {
                    await http
                        .post(`/helpers/upload-image`, formData)
                        .then(async (res) => {
                            data["logo"] = res?.data?.data[0]?.src;
                            try {
                                await http
                                    .post(`/company`, data)
                                    .then(async (res) => {
                                        history.push("/admin/company");
                                        toast.success(
                                            t("toast.success.addedSuccessfully")
                                        );
                                    });
                                reset({});
                            } catch (res) {
                                toast.error(res?.response?.data?.message);
                            }
                        });
                } catch (res) {
                    toast.error(res?.response?.data?.message);
                }
            } else {
                try {
                    await http.post(`/company`, data).then(async (res) => {
                        history.push("/admin/company");
                        toast.success(t("toast.success.addedSuccessfully"));
                    });
                    reset({});
                } catch (res) {
                    toast.error(res?.response?.data?.message);
                }
            }
        }
        setProgress(CREATE_PAYOUT_BTN, false);
    };

    return (
        <ResourcesList
            BeforeHeader={
                <>
                    <Typography variant="h6" color="textPrimary">
                        {t("admin.company.title")}
                    </Typography>
                </>
            }
            Section={
                <Card variant="outlined" className="p-5">
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <h3 className={classes.h3Text}>
                                {shortReadId
                                    ? t("common.updateDetails")
                                    : t("common.createNew")}
                            </h3>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <IconButton
                                        onClick={() => history.goBack()}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: "10px 0" }} />
                    <form
                        onSubmit={handleSubmit(handleFormSubmission)}
                        className="space-y-3 py-5"
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} md={6} lg={5} xl={4}>
                                        <InputController
                                            size="small"
                                            control={control}
                                            name="title"
                                            label={t(
                                                "admin.company.create.title"
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} md={6} lg={5} xl={4}>
                                        <InputController
                                            size="small"
                                            control={control}
                                            name="code"
                                            label={t(
                                                "admin.company.companyCode"
                                            )}
                                            inputProps={{
                                                pattern: "[0-9]*",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroupController
                                    label={t("admin.company.category")}
                                    control={control}
                                    name="category"
                                    row
                                    options={[
                                        {
                                            label: t(
                                                "admin.company.create.Listed"
                                            ),
                                            value: "listed",
                                        },
                                        {
                                            label: t(
                                                "admin.company.create.Market"
                                            ),
                                            value: "market",
                                        },
                                        {
                                            label: t(
                                                "admin.company.create.Other"
                                            ),
                                            value: "other",
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {selectedCategory !== "other" ? (
                                    <RadioGroupController
                                        label={t("admin.company.status")}
                                        control={control}
                                        name="status"
                                        row
                                        options={[
                                            {
                                                label: t(
                                                    "admin.company.create.active"
                                                ),
                                                value: "active",
                                            },
                                            {
                                                label: t(
                                                    "admin.company.create.pending"
                                                ),
                                                value: "pending",
                                            },
                                            {
                                                label: t(
                                                    "admin.company.create.cancelled"
                                                ),
                                                value: "cancelled",
                                            },
                                        ]}
                                    />
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <h6
                                            className="labelForm"
                                            style={{ marginBottom: "15px" }}
                                        >
                                            {t("admin.article.image")}
                                        </h6>
                                        <ImageController
                                            name="logo"
                                            filesLimit={1}
                                            acceptedFiles
                                            form={form}
                                            errors={errors}
                                        />
                                    </Grid>
                                    {shortReadId ? (
                                        <Grid item xs={12}>
                                            <img
                                                src={`${shortReadQuery?.data?.data?.logo}`}
                                                alt="headerImg"
                                                style={{ width: "80px" }}
                                            />
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className="flex pt-3">
                            <Btn
                                fullWidth={false}
                                type="submit"
                                name={CREATE_PAYOUT_BTN}
                            >
                                {t("common.submit")}
                            </Btn>
                        </div>
                    </form>
                </Card>
            }
        />
    );
}

export default CreateUpdateCompany;
