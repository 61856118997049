import React from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { IconBtnBase, ResourcesList } from 'Components';
// import { Can } from 'Utils/Can';
import { makeStyles } from '@mui/styles';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { PROCESSDETAIL } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';


const useStyles = makeStyles((theme) => ({
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    table_container: {
        minHeight: '250px',
    },
    tableHead: {
        fontSize: '14px',
        fontWeight: 500,
        backgroundColor: 'rgba(231, 231, 231, 1)',
        color: 'rgba(0, 0, 0, 1)',
        lineHeight: '22px',
    },
    tableContent: {
        fontSize: '14px',
        color: 'rgba(77, 79, 92, 1)',
        lineHeight: '22px'
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    }
}))

// Component goes here
const ProcessGuide = () => {
    const { path } = useRouteMatch();
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const columns = [
        { id: 'number', label: t("admin.article.number"), minWidth: '100px', width: '100px' },
        { id: 'subCategory', label: t("admin.article.subCategory"), minWidth: '170px' },
    ]
    const processGuideQuerry = useQuery([PROCESSDETAIL], () =>
    // http.get(`/article`, {}).then(({ data }) => data),
    http.get(`/process_guide`, {}).then(({ data }) => data),
);


    return (
        <ResourcesList
            headerProps={{
                title: t('admin.guide.processGuide'),
                EndSide: (
                    <div className='flex'>
                        {/* <Can I="CREATE" this="USER"> */}
                            <IconBtnBase
                            className={classes.btnEdit}
                                onClick={() => {history.push(`${path}/edit`);}}
                                size="large">
                                <BorderColorOutlinedIcon />
                            </IconBtnBase>
                        {/* </Can> */}
                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <Grid container>
                            <Grid item xs={8}>
                                <img src={processGuideQuerry?.data?.data?.attachment[0]?.src} alt=''
                                style={{ width: "100%" }} 
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container>
                                    <TableContainer className={classes.table_container}>
                                        <Table>
                                            <TableHead style={{ position: 'sticky', top: '0' }}>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            className={classes.tableHead}
                                                            style={{ minWidth: column.minWidth, width: column.width }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {processGuideQuerry?.data?.data?.items ? processGuideQuerry?.data?.data?.items?.map((row, index) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={row[0]}>
                                                            {columns.map((column) => {
                                                                return (
                                                                    <TableCell className={classes.tableContent} key={column.id} align={column.align}>
                                                                        {row[column.id]}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                }) : null}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            }
        />
    );
};

export default ProcessGuide;
