export const PermissionArray = (value) => {
  let arrayy = []

  switch (value?.isCreateUpdate) {
    case true:
      arrayy.push("isCreateUpdate")
      break;
    default:
      break;
  }

  switch (value?.isDelete) {
    case true:
      arrayy.push("isDelete")
      break;

    default:
      break;
  }
  return arrayy
}

export const ImageControllerConversion = (value) => {
  let array = []
  if (value?.length) {
    for (let i = 0; i < value?.length; i++) {
      array[i] = {
        preview: value?.src,
        name: value?.originalName,
      };
    }
  }
  return array
}
export const SelectedGender = (value) => {
  let gender = []

  switch (value?.isBoy) {
    case true:
      gender.push("Boy")
      break;
    default:
      break;
  }

  switch (value?.isGirl) {
    case true:
      gender.push("Girl")
      break;

    default:
      break;
  }
  return gender
}

export const SelectedSchool = (value) => {
  let school = []

  switch (value?.isElementarySchool) {
    case true:
      school.push("Elementary")
      break;
    default:
      break;
  }

  switch (value?.isMiddleSchool) {
    case true:
      school.push("Middle")
      break;
    default:
      break;
  }

  switch (value?.isHighSchool) {
    case true:
      school.push("High")
      break;

    default:
      break;
  }
  return school
}

export const SelectedViewers = (value) => {
  let viewers = []

  switch (value?.isParent) {
    case true:
      viewers.push("Parent")
      break;
    default:
      break;
  }

  switch (value?.isStudent) {
    case true:
      viewers.push("Student")
      break;
    default:
      break;
  }

  switch (value?.isTeacher) {
    case true:
      viewers.push("Teacher")
      break;

    default:
      break;
  }
  return viewers
}

export const HookFormDefaultValue = (value) => {
  let data = {}

  if (value) {
    data = {
      title: value.title,
    }

  } else {
    data = null
  }
  return data
}