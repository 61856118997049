import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { SHORTREADDETAIL } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { ResourcesList } from 'Components';
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import NewsListingPage from './NewsListingPage'
import QnAListingPage from './QnA/QnAListingPage'
import { Can } from 'Utils/Can';




const styles = {
    tableSize: {
        minHeight: 'calc(100vh - 250px)',
        width: '100%',
    },
    h5Text: {
        textTransform: 'capitalize',
        color: 'red',
    }
};

const useStyles = makeStyles((theme) => ({
    h5Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)'
    },
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    pText: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,0.8)'
    },
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    }
}))

// Component goes here
const CompanyDetailPage = () => {
    const spacing = 4
    const classes = useStyles();
    const history = useHistory();
    const { shortReadId } = useParams();
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const [openDeleteModal, setOpenDeleteModal] = useState(false)


    const shortReadQuery = useQuery([SHORTREADDETAIL, { shortReadId }], () =>
        http.get(`/company/${shortReadId}`, {}).then(({ data }) => data),
    );

    const handleSoftDelete = async () => {
        try {
            await http.delete(`/company/${shortReadId}`, {})
                .then(async (res) => {
                    toast.success(t('toast.success.deletedSuccessfully'))
                    history.goBack();
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <ResourcesList
                    BeforeHeader={
                        <>
                            <div className='flex justify-center'>
                                <img src={`${shortReadQuery?.data?.data?.logo}`} alt="headerImg" /><br></br>

                            </div>
                            <div className='flex-col flex text-center'>
                                <p>{shortReadQuery?.data?.data?.title}</p><br></br>
                                <p>{shortReadQuery?.data?.data?.category}</p><br></br>
                            </div>
                        </>
                    }
                    Section={
                        <div className="mt-8">
                            <Box sx={styles.tableSize}>
                                <Paper>
                                    <Card variant="outlined" style={{ padding: '20px' }}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item>
                                                <h3 className={classes.h3Text}>{t("admin.company.companyDetails")}</h3>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item>
                                                        <Can I="CREATE" this="COMPANY">
                                                            <IconButton className={classes.btnEdit} onClick={() => history.push(`/admin/company/edit/${shortReadId}`)} ><BorderColorOutlinedIcon /></IconButton>
                                                        </Can>
                                                    </Grid>
                                                    <Grid item>
                                                        <Can I="DELETE" this="COMPANY">
                                                            <IconButton className={classes.btnClose} onClick={() => setOpenDeleteModal(true)} ><DeleteOutlineOutlinedIcon /></IconButton>
                                                        </Can>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider style={{ margin: '10px 0' }} />
                                        {/* <Grid container justifyContent="space-between">
                                            <Grid item xs={8}>
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                        <h5 className={classes.h5Text}>{t("admin.company.title")}:</h5>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p className={classes.pText}>{shortReadQuery?.data?.data?.title}</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                        <h5 className={classes.h5Text}>{t("admin.company.category")}:</h5>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p className={classes.pText}>{shortReadQuery?.data?.data?.category}</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <img src={shortReadQuery?.data?.data?.logo} style={{height:"100px"}}/>
                                            </Grid>
                                        </Grid> */}
                                        <Grid container spacing={spacing}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={spacing}>
                                                    <Grid item xs={12}>
                                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                                <Tab label={t("admin.company.news.title")} {...a11yProps(0)} />
                                                                <Tab label={t("admin.company.qna.title")} {...a11yProps(1)} />
                                                            </Tabs>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TabPanel value={value} index={0}>
                                                            <NewsListingPage companyId={shortReadId} />
                                                        </TabPanel>
                                                        <TabPanel value={value} index={1}>
                                                            <QnAListingPage companyId={shortReadId} />
                                                        </TabPanel>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Box>
                            <ConfirmationDialog isOpen={openDeleteModal} title={t("common.delete")} text={t("delete.deleteCompany")} onReject={() => setOpenDeleteModal(false)} onResolve={handleSoftDelete} />
                        </div>
                    }
                />
            </Grid>
        </>
    );
};

export default CompanyDetailPage;
