import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, IconButton, InputAdornment, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Btn, InputController, setProgress } from 'Components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { http } from 'Utils/Http/Http';

const CHANGE_LINK_BTN = 'CHANGE_LINK_BTN';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: "800px",
    borderRadius: "20px",
    bgcolor: 'background.paper',
    // borderRadius:"20px",
    boxShadow: 24,
    p: 4,
};

const useStyle = makeStyles((theme) => ({
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    title: {
        fontSize: "23px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "500"
    },
}));


const schema = yup.object().shape({
    password: yup.string().required(),
    newPassword: yup.string().required(),
    confirmNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'Password and Confirm Password do not match.'),
});


const ImageViewModal = ({ open, handleClose, item }) => {
    const classes = useStyle();
    const { t } = useTranslation();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open} style={{height:'100vh', overflow:'scroll'}}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <h3 className={classes.title}>Preview</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <Grid container style={{ marginTop: "10px",justifyContent:'center' }} spacing={5}>
                        <Grid item>
                            <img src={item}  style={{height:'100%'}}/>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ImageViewModal
