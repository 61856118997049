import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  ResourcesList,
  EditorController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { SHORTREADDETAIL } from "Constants/QueriesKeys";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";


const closingss = Yup.object({
  closingDescriptionRating: Yup
    .number()
  // .required("Required"),
});

const itemss = Yup.object({
  closing: Yup
    .array()
    .of(closingss)
});

const schema = Yup.object().shape({
  questionDescription: Yup.string().required("required"),
  title: Yup.string().required("required"),
  items: Yup
    .array()
    .of(itemss)
});

const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function UpdateQnA() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { shortReadId } = useParams();
  const { companyId } = useParams();

  const shortReadQuery = useQuery([SHORTREADDETAIL, { shortReadId }], () =>
    http.get(`/question_and_answer/${shortReadId}`, {}).then(({ data }) => data),
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: shortReadQuery?.data?.data
  });


  const { control, handleSubmit, setError, reset, errors } = form;

  const handleFormSubmission = async (values) => {
    // return;
    setProgress(CREATE_PAYOUT_BTN, true);
    // values['companyId'] = companyId;

    const data = {
      title: values.title,
      questionDescription: values.questionDescription,
      companyId,
      items: shortReadQuery?.data?.data?.items,
    }
    
    try {
      await http.put(`/question_and_answer/${shortReadId}`, data)
        .then(async (res) => {
          history.goBack();
          toast.success(t('toast.success.updatedSuccessfully'));
        });
      // reset({});
    } catch (res) {
      toast.error(res?.response?.data?.message)
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.company.qna.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{shortReadId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="title"
                      label={t("admin.company.qna.questionTitle")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <EditorController
                      control={control}
                      name="questionDescription"
                      label={t('admin.company.qna.questionDesc')}
                    />
                  </Grid>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                {t("common.submit")}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}

export default UpdateQnA;
