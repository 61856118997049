import React, { useEffect, useState } from 'react';
import { Paper, TextField, Grid, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { ARTICLELIST } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DataGrid, IconBtnBase, ResourcesList } from 'Components';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Can } from 'Utils/Can';

const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};

// Component goes here
const ArticlePage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const perPage = 10;
    const [searchArticle, setSearchArticle] = useState("");
    const [articleListing, setArticleListing] = useState();
    const articleListRender = useQuery([ARTICLELIST, {searchArticle }], () =>
        http.get(`/article`, {
            params: {
                page: page,
                limit: perPage,
                searchString: searchArticle
            }
        }).then(({ data }) => { setArticleListing(data?.data?.data); return (data) }),
    );

    useEffect(() => {
        http.get(`/article`, {
            params: {
                page: page,
                limit: perPage,
                searchString: searchArticle
            }
        }).then(({ data }) => { setArticleListing(data?.data?.data); })
    }, [page]);

    const columns = [
        {
            field: 'title',
            headerName: t('admin.company.title'),
            minWidth: 200,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.title.charAt(0).toUpperCase() + row?.row?.title.slice(1)}
                    </div>
                );
            },
        },
        {
            field: 'category',
            headerName: t('admin.company.category'),
            minWidth: 200,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.category}
                    </div>
                );
            },
        },
        {
            field: 'subCategory',
            headerName: t('admin.company.subCategory'),
            minWidth: 150,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        {row?.row?.subCategory ? row?.row?.subCategory.charAt(0).toUpperCase() + row?.row?.subCategory.slice(1) : "-"}
                    </div>
                );
            },
        },
        {
            field: 'description',
            headerName: t('admin.company.description'),
            minWidth: 150,
            editable: false,
            renderCell: (row) => {
                return (
                    // <p className="flex items-center">
                    //     {row?.row?.description.split('<br>')[0].slice(0, 30) + '...'}
                    // </p>
                    <div className="flex items-center" dangerouslySetInnerHTML={{ __html: row?.row?.description }}></div>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase
                            onClick={() => history.push(`${path}/details/${row.id}`)}
                            size="large">
                            <VisibilityIcon />
                        </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    const rowData = []

    if (articleListing?.length > 0) {
        for (let i = 0; i < articleListing?.length; i++) {
            rowData[i] = {
                title: articleListing[i]?.title,
                id: articleListing[i]?._id,
                category: articleListing[i]?.category,
                subCategory: articleListing[i]?.subCategory,
                description: articleListing[i]?.description,
            }
        }
    }

    // if (accountQuery.isLoading) {
    //     return <ResourceListSkeleton />;
    // }
    return (
        <ResourcesList
            headerProps={{
                title: t('admin.article.title'),
                EndSide: (
                    <div className='flex'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item >
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder={t("search.searchArticle")}
                                    id="outlined-start-adornment"
                                    name="searchArticle"
                                    variant="outlined"
                                    onChange={(e) => setSearchArticle(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item >
                                <Can I="CREATE" this="ARTICLE">
                                    <IconBtnBase
                                        onClick={() => {
                                            history.push(`${path}/create`);
                                        }}
                                        size="small">
                                        <AddIcon />
                                    </IconBtnBase>
                                </Can>
                            </Grid>
                            <Grid item >
                                <IconBtnBase
                                    onClick={() => {
                                        articleListRender.refetch();
                                    }}
                                    size="small">
                                    <RefreshIcon />
                                </IconBtnBase>
                            </Grid>
                        </Grid>
                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={styles.tableSize}>
                        <Paper>
                            <DataGrid
                                pagination
                                rowCount={articleListRender?.data?.data?.totalCount}
                                paginationMode="server"
                                onPageChange={(newPage) => setPage(newPage + 1)}
                                loading={articleListRender?.isLoading}
                                rows={rowData}
                                columns={columns}
                                pageSize={perPage}
                            />
                        </Paper>
                    </Box>
                </div>
            }
        />
    );
};

export default ArticlePage;
