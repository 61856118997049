import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  ResourcesList,
  EditorController,
  RadioGroupController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import FileController from "Components/FormControls/Controllers/FileController";
import RatingController from "Components/FormControls/Controllers/RatingController";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";


const closingss = Yup.object({
  closingDescriptionRating: Yup
    .number()
  // .required("Required"),
});


const schema = Yup.object().shape({
  questionDescription: Yup.string().required("required"),
  title: Yup.string().required("required"),
  closing: Yup
    .array()
    .of(closingss)
});

const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  h4Text: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  },
  btn_delete: {
    fontSize: "12px",
    textTransform: "capitalize",
    color: "rgba(255,0,0,0.5)",
    margin: "0 20px",
    '&:hover': {
      color: 'rgba(255,0,0,0.7)',
    }
  },
}))

function CreateQnA() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { shortReadId } = useParams();
  const { companyId } = useParams();
  const spacing = 3

  const form = useForm({
    resolver: yupResolver(schema),
  });


  const { control, handleSubmit, setError, reset, errors } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "closing"
  });

  if (fields?.length < 1) {
    append({
      closingDescriptionText: "",
      closingDescriptionRating: 2
    })
  }


  const handleFormSubmission = async (values) => {
    // return;
    setProgress(CREATE_PAYOUT_BTN, true);

    let formDataAwareness = new FormData();

    for (var i = 0; i < values.awarenessMaterialFiles?.length; i++) {
      formDataAwareness.append("attachment", values.awarenessMaterialFiles[i]);
    }

    let formDataCommittee = new FormData();

    for (var j = 0; j < values.committeeDecisionsFiles?.length; j++) {
      formDataCommittee.append("attachment", values.committeeDecisionsFiles[j]);
    }

    const data = {
      title: values.title,
      questionDescription: values.questionDescription,
      companyId,
      items: [
        {
          answerDescription: values?.answerDescription,
          closing: values?.closing
        }
      ],
      awareness: {
        description: values?.awarenessDescription,
        status: 'completed',
      },
      committee: {
        decisionType: values?.category,
        status: 'completed',
      }
    }
    if (values.awarenessMaterialFiles?.length) {
      if (values.committeeDecisionsFiles?.length) {
        try {
          await http.post(`/helpers/upload-image`, formDataAwareness)
            .then(async (res) => {
              data.awareness['attachment'] = res?.data?.data
              try {
                await http.post(`/helpers/upload-image`, formDataCommittee)
                  .then(async (res) => {
                    data.committee['attachment'] = res?.data?.data
                    try {
                      await http.post(`/question_and_answer`, data)
                        .then(async (res) => {
                          history.goBack();
                          toast.success(t('toast.success.addedSuccessfully'));
                        });
                      // reset({});
                    } catch (error) {
                      // debugger
                      toast.error(error?.response?.data?.message);
                    }
                  });
              } catch (res) {
                toast.error(res?.response?.data?.message)
              }
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      } else {
        toast.error("Select Committee Decisions Files.")
      }
    } else {
      toast.error("Select Awareness Material Files.")
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.company.qna.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{shortReadId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5"
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={spacing}>
                  <Grid item xs={12}>
                    <h3 className={classes.h4Text}>{t('admin.company.qna.question')}</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={12} xl={12}>
                        <InputController
                          size="small"
                          control={control}
                          name="title"
                          label={t("admin.company.qna.questionTitle")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <EditorController
                      control={control}
                      name="questionDescription"
                      label={t('admin.company.qna.questionDesc')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: "10px 0" }} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={spacing}>
                  <Grid item xs={12}>
                    <h3 className={classes.h4Text}>{t('admin.company.qna.answer')}</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container >
                          <Grid item xs={12} md={12} lg={12} xl={12}>
                            <EditorController
                              control={control}
                              name={`answerDescription`}
                              label={t('admin.company.qna.answerDesc')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {fields.map((data, index) => {
                        return (
                          <Grid item xs={12}>
                            <Grid container >
                              <Grid item xs={8} md={8} lg={8} xl={8}>
                                <EditorController
                                  control={control}
                                  name={`closing[${index}].closingDescriptionText`}
                                  label={t('admin.company.qna.closingDesc')}
                                />

                              </Grid>
                              <Grid item xs={4} md={4} lg={4} xl={4}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div style={{ margin: "30px" }}>
                                      <RatingController
                                        control={control}
                                        name={`closing[${index}].closingDescriptionRating`}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {fields?.length > 1 ?
                                      <IconButton className={classes.btn_delete} onClick={() => remove(index)}><DeleteOutlineIcon /></IconButton>
                                      : null}
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={8} md={8} lg={8} xl={8}
                            onClick={(e) => {
                              e.preventDefault();
                              append({
                                closingDescriptionText: "",
                                closingDescriptionRating: 2
                              });
                            }}
                            style={{ cursor: 'pointer' }}>
                            + {t("admin.company.qna.closingDesc")}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: "10px 0" }} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={spacing}>
                  <Grid item xs={12}>
                    <h3 className={classes.h4Text}>{t('admin.company.qna.awarenessMaterial')}</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <EditorController
                      control={control}
                      name="awarenessDescription"
                      label={t('admin.company.qna.description')}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <Grid container >
                      <Grid item xs={12}>
                        <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.company.qna.fileUpload")}</h6>
                      </Grid>
                      <FileController
                        name="awarenessMaterialFiles"
                        filesLimit={5}
                        acceptedFiles
                        form={form}
                        errors={errors}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: "10px 0" }} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={spacing}>
                  <Grid item xs={12}>
                    <h3 className={classes.h4Text}>{t('admin.company.qna.committeeDecisions')}</h3>
                  </Grid>
                  <Grid item xs={12} >
                    <Grid container >
                      <Grid item xs={12}>
                        <RadioGroupController
                          // label={t('admin.company.category')}
                          control={control}
                          name="category"
                          row
                          options={[{ label: t('admin.company.qna.decisionCompensate'), value: "Decision to Compensate" }, { label: t('admin.company.qna.decisionRefuseCompensation'), value: "Decision to refuse compensation" }]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.company.qna.fileUpload")}</h6>
                      </Grid>
                      <FileController
                        name="committeeDecisionsFiles"
                        filesLimit={5}
                        acceptedFiles
                        form={form}
                        errors={errors}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                {t("common.submit")}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}

export default CreateQnA;
