import './index.css';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import { setLocale } from 'yup';
import SignInPage from 'Pages/Auth/SignInPage';
import { useEffect, useState } from 'react';
import { http } from 'Utils/Http/Http';
import App from 'App';
import { LinearProgress } from '@mui/material';
import { AuthContext } from 'Contexts/AuthContext';
import GuardedRoute from 'Utils/Guards/GuardedRoute';
import AuthGuard from 'Utils/Guards/AuthGuard';
import GuestGuard from 'Utils/Guards/GuestGuard';
import SendResetLinkPage from 'Pages/Auth/SendResetLinkPage';
import ResetPasswordPage from 'Pages/Auth/ResetPasswordPage';
import { LocalStorage } from 'Utils/LocalStorage';
import { AbilityBuilder, Ability } from '@casl/ability';
import { AbilityContext, ability } from './Utils/Can';
const storage = new LocalStorage();

setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    required: 'required',
  },
});

function updateAbility(perms) {
  const { can, rules } = new AbilityBuilder(Ability);
  perms?.article?.isCreateUpdate && can("CREATE", "ARTICLE");
  perms?.article?.isDelete && can("DELETE", "ARTICLE");
  perms?.company?.isCreateUpdate && can("CREATE", "COMPANY");
  perms?.company?.isDelete && can("DELETE", "COMPANY");
  perms?.customerExecutive?.isCreateUpdate && can("CREATE", "CUSTOMER");
  perms?.customerExecutive?.isDelete && can("DELETE", "CUSTOMER");
  perms?.users?.isCreateUpdate && can("CREATE", "USER");
  perms?.users?.isDelete && can("DELETE", "USER");
  perms?.request?.isCreateUpdate && can("CREATE", "REQUEST");
  perms?.request?.isDelete && can("DELETE", "REQUEST");
  // perms.map((perm) => {
      
  //     can(perm.action, perm.subject);
  // });
  //can('VIEW', 'REQUESTS');
  // perms?.company?.isCreateUpdate && can("DELETE", "COMPANY");
  // perms?.company?.isDelete && can("DELETE", "COMPANY");
  // perms?.customerExecutive?.isCreateUpdate && can("DELETE", "CUSTOMER");
  // perms?.customerExecutive?.isDelete && can("DELETE", "CUSTOMER");
  // perms?.request?.isCreateUpdate && can("DELETE", "REQUEST");
  // perms?.request?.isDelete && can("DELETE", "REQUEST");
  // perms?.users?.isCreateUpdate && can("DELETE", "USERS");
  // perms?.users?.isDelete && can("DELETE", "USERS");
  
  ability && ability.update(rules);
}

function Entry() {
  const { t } = useTranslation();
  const [isFetchingUserData, setIsFetchingUserData] = useState(true);
  const [auth, setAuth] = useState({
    user: null,
    fetchUserProfile: () => {
      return http
        .get('/team/profile')
        .then((response) => {
          setAuth({ ...auth, user: response.data.data });
          updateAbility(response?.data?.data?.permission);
        })
        .catch(() => {
          storage.remove('userToken');
          storage.remove('user');
          setAuth({ ...auth, user: null });
        });
    },
    // refreshCsrf: () => {
    //     return http.get(`/sanctum/csrf-cookie`, {
    //         baseURL: process.env.REACT_APP_BASE_URL,
    //     });
    // },
  });

  useEffect(() => {
    setIsFetchingUserData(true);
    auth.fetchUserProfile().finally(() => setIsFetchingUserData(false));
    // auth.refreshCsrf();
  }, []);

  return (
    <AbilityContext.Provider value={ability}>
      <AuthContext.Provider value={auth}>
        {isFetchingUserData ? (
          <div className="h-screen flex justify-center items-center">
            <LinearProgress variant="query" className="w-72" />

          </div>) : (
          <Switch>
            <GuardedRoute
              guards={[GuestGuard]}
              exact
              path="/sign-in"
              component={SignInPage}
            />
            <Route exact path="/forgot-password" component={SendResetLinkPage} />
            <Route exact path="/forget-password/:emailId/:token" component={ResetPasswordPage} />
            <Route exact path="/email_verified/:emailId/:token" component={ResetPasswordPage} />
            <Route exact path="/" render={() => {
              return (
                //   isFetchingUserData ?
                // <Redirect to="/si" /> :
                <Redirect to="/admin" />
              )
            }} />
            <GuardedRoute guards={[AuthGuard]} path="/admin" component={App} />
            {/* <Route path="/admin" component={App} /> */}
          </Switch>
        )}
      </AuthContext.Provider>
     </AbilityContext.Provider> 

  );
}

export default Entry;
