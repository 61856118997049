import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  ResourcesList,
  CheckboxGroupController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import ImageController from "Components/FormControls/Controllers/ImageController";
import { useQuery } from "react-query";
import { USERDETAIL } from "Constants/QueriesKeys";
import { PermissionArray } from "Components/HookFormDefaultValue/HookFormDefaultValue";
import { LocalStorage } from 'Utils/LocalStorage';

const storage = new LocalStorage();


const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  name: Yup.string().required("required"),
  email: Yup.string().required("required"),
  article: Yup.array().nullable(),
  company: Yup.array().nullable(),
  users: Yup.array().nullable(),
  customerExecutive: Yup.array().nullable(),
  request: Yup.array().nullable(),
});


const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function CreateUpdateUser() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { userId, userType } = useParams()
  const permissionArray = storage.get("user").permission


  const userDetailQuery = useQuery([USERDETAIL, { userId }], () =>
    userId ?
      typeOfUser === "customer-executive" ?
        http.get(`/customer_executive/${userId}`, {}).then(({ data }) => data)
        :
        http.get(`/team/${userId}`, {}).then(({ data }) => data)
      : null
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: userDetailQuery ?
      {
        name: userDetailQuery?.data?.data?.name,
        email: userDetailQuery?.data?.data?.email,
        article: PermissionArray(userDetailQuery?.data?.data?.permission?.article),
        company: PermissionArray(userDetailQuery?.data?.data?.permission?.company),
        users: PermissionArray(userDetailQuery?.data?.data?.permission?.users),
        customerExecutive: PermissionArray(userDetailQuery?.data?.data?.permission?.customerExecutive),
        request: PermissionArray(userDetailQuery?.data?.data?.permission?.request)
      } : null
  });


  const { control, handleSubmit, setError, reset, errors } = form;

  const [typeOfUser, setTypeOfUser] = useState(userType)

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    var data = {}
    if (typeOfUser === "team-member") {
      data = {
        name: values?.name,
        email: values?.email,
        permission: {
          article: {
            isCreateUpdate: values?.article ? values?.article?.find((item) => item === "isCreateUpdate") ? true : false : false,
            isDelete: values?.article ? values?.article?.find((item) => item === "isDelete") ? true : false : false,
          },
          company: {
            isCreateUpdate: values?.company ? values?.company?.find((item) => item === "isCreateUpdate") ? true : false : false,
            isDelete: values?.company ? values?.company.find((item) => item === "isDelete") ? true : false : false,
          },
          request: {
            isCreateUpdate: values?.request ? values?.request?.find((item) => item === "isCreateUpdate") ? true : false : false,
            isDelete: values?.request ? values?.request?.find((item) => item === "isDelete") ? true : false : false,
          },
          users: {
            isCreateUpdate: values?.users ? values?.users?.find((item) => item === "isCreateUpdate") ? true : false : false,
            isDelete: values?.users ? values?.users?.find((item) => item === "isDelete") ? true : false : false,
          },
          customerExecutive: {
            isCreateUpdate: values?.customerExecutive ? values?.customerExecutive?.find((item) => item === "isCreateUpdate") ? true : false : false,
            isDelete: values?.customerExecutive ? values?.customerExecutive?.find((item) => item === "isDelete") ? true : false : false,
          }
        }
      }
    } else {
      data = {
        name: values?.name,
        email: values?.email,
      }
    }

    let formData = new FormData();

    for (var i = 0; i < values?.profilePic?.length; i++) {
      formData.append("dv", values?.profilePic[i]);
    }

    if (userId) {
      if (values?.profilePic?.length) {
        try {
          await http.post(`/helpers/upload-image`, formData)
            .then(async (res) => {
              data['profilePic'] = res?.data?.data[0]?.src
              if (typeOfUser === "team-member") {
                try {
                  await http.put(`/team/${userId}`, data)
                    .then(async (res) => {
                      history.goBack()
                      toast.success(t('toast.success.addedSuccessfully'))
                    });
                  reset({});
                } catch (res) {
                  toast.error(res?.response?.data?.message)
                }
              } else {
                try {
                  await http.put(`/customer_executive/${userId}`, data)
                    .then(async (res) => {
                      history.goBack()
                      toast.success(t('toast.success.addedSuccessfully'))
                    });
                  reset({});
                } catch (res) {
                  toast.error(res?.response?.data?.message)
                }
              }
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      } else {
        data['profilePic'] = userDetailQuery?.data?.data?.profilePic
        if (typeOfUser === "team-member") {
          try {
            await http.put(`/team/${userId}`, data)
              .then(async (res) => {
                history.goBack()
                toast.success(t('toast.success.addedSuccessfully'))
              });
            reset({});
          } catch (res) {
            toast.error(res?.response?.data?.message)
          }
        } else {
          try {
            await http.put(`/customer_executive/${userId}`, data)
              .then(async (res) => {
                history.goBack()
                toast.success(t('toast.success.addedSuccessfully'))
              });
            reset({});
          } catch (res) {
            toast.error(res?.response?.data?.message)
          }
        }
      }
    } else {
      if (values?.profilePic?.length) {
        try {
          await http.post(`/helpers/upload-image`, formData)
            .then(async (res) => {
              data['profilePic'] = res?.data?.data[0]?.src
              if (typeOfUser === "team-member") {
                try {
                  await http.post(`/team`, data)
                    .then(async (res) => {
                      history.push('/admin/users');
                      toast.success(t('toast.success.addedSuccessfully'))
                    });
                  reset({});
                } catch (res) {
                  toast.error(res?.response?.data?.message)
                }
              } else {
                try {
                  await http.post(`/customer_executive`, data)
                    .then(async (res) => {
                      history.push('/admin/users');
                      toast.success(t('toast.success.addedSuccessfully'))
                    });
                  reset({});
                } catch (res) {
                  toast.error(res?.response?.data?.message)
                }
              }
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      } else {
        if (typeOfUser === "team-member") {
          try {
            await http.post(`/team`, data)
              .then(async (res) => {
                history.push('/admin/users');
                toast.success(t('toast.success.addedSuccessfully'))
              });
            reset({});
          } catch (res) {
            toast.error(res?.response?.data?.message)
          }
        } else {
          try {
            await http.post(`/customer_executive`, data)
              .then(async (res) => {
                history.push('/admin/users');
                toast.success(t('toast.success.addedSuccessfully'))
              });
            reset({});
          } catch (res) {
            toast.error(res?.response?.data?.message)
          }
        }
      }
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.users.users')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{userId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5 col-span-4 w-6/12"
          >
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t("admin.users.typeOfUser")}</FormLabel>
                  <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                    value={typeOfUser}
                    onChange={(e) => setTypeOfUser(e.target.value)}
                  >
                    {permissionArray?.users?.isCreateUpdate ?
                      <FormControlLabel value="team-member" control={<Radio />} label={t('admin.users.teamMember')} />
                      :
                      <FormControlLabel value="team-member" control={<Radio />} label={t('admin.users.teamMember')} disabled />
                    }
                    {permissionArray?.users?.isCreateUpdate ?
                      <FormControlLabel value="customer-executive" control={<Radio />} label={t('admin.users.customerExecutive')} />
                      :
                      <FormControlLabel value="customer-executive" control={<Radio />} label={t('admin.users.customerExecutive')} disabled />
                    }
                  </RadioGroup>
                </FormControl>
              </Grid>
              {typeOfUser ?
                <>
                  <Grid item xs={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="name"
                      label={t("admin.users.create.name")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputController
                      size="small"
                      control={control}
                      name="email"
                      label={t("admin.users.create.email")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.users.create.profilePic")}</h6>
                    <ImageController
                      name="profilePic"
                      filesLimit={1}
                      acceptedFiles
                      form={form}
                      errors={errors}
                    />
                    {userDetailQuery?.data?.data?.profilePic ?
                      <img src={userDetailQuery?.data?.data?.profilePic} alt="" style={{height:"100px"}}/>
                      : null}
                  </Grid>
                  {typeOfUser === "team-member" ?
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.users.create.permissions")}</h6>
                        </Grid>
                        <Grid item xs={12}>
                          <CheckboxGroupController
                            label={t('admin.users.create.article')}
                            control={control}
                            name="article"
                            row
                            options={[{ label: t('admin.users.create.createUpdate'), value: 'isCreateUpdate' }, { label: t('admin.users.create.delete'), value: 'isDelete' }]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CheckboxGroupController
                            label={t('admin.users.create.company')}
                            control={control}
                            name="company"
                            row
                            options={[{ label: t('admin.users.create.createUpdate'), value: 'isCreateUpdate' }, { label: t('admin.users.create.delete'), value: 'isDelete' }]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CheckboxGroupController
                            label={t('admin.users.create.request')}
                            control={control}
                            name="request"
                            row
                            options={[{ label: t('admin.users.create.createUpdate'), value: 'isCreateUpdate' }, { label: t('admin.users.create.delete'), value: 'isDelete' }]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CheckboxGroupController
                            label={t('admin.users.create.user')}
                            control={control}
                            name="users"
                            row
                            options={[{ label: t('admin.users.create.createUpdate'), value: 'isCreateUpdate' }, { label: t('admin.users.create.delete'), value: 'isDelete' }]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CheckboxGroupController
                            label={t('admin.users.create.customerExecutive')}
                            control={control}
                            name="customerExecutive"
                            row
                            options={[{ label: t('admin.users.create.createUpdate'), value: 'isCreateUpdate' }, { label: t('admin.users.create.delete'), value: 'isDelete' }]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    : null}
                  <Grid item xs={12}>
                    <div className="flex pt-3">
                      <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                        {t("common.submit")}
                      </Btn>
                    </div>
                  </Grid>
                </>
                : null}
            </Grid>
          </form>
        </Card>
      }
    />
  );
}

export default CreateUpdateUser;
